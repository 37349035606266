import BaseCtrl from "_pages/common/baseCtrl";
import {dateFormat} from "_services/transformers";
// import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
// import PhotoSwipe from "photoswipe";
// import 'photoswipe/dist/photoswipe.css'
// import 'photoswipe/dist/default-skin/default-skin.css'

export default class LqPaveRealTimeCloudPictureCtrl extends BaseCtrl {
    constructor($scope, $rootScope, $interval, systemService) {
        super();
        this.scope = $scope;
        this.rootScope = $rootScope;
        this.interval = $interval;
        this.materialType = 'lq';
        this.requestError = false;
        this.systemService = systemService;

        console.log(this.rootScope,1111)

        this.paveCloudPicture = {

            standard: 'tempSeg',
            // 工程
            engineerId: this.rootScope.cloudpicture.engineerId,
            // 标段
            sectionId: this.rootScope.cloudpicture.sectionId,
            // 工区
            workAreaId: this.rootScope.cloudpicture.workAreaId,
            // 层位列表
            layerList: this.rootScope.cloudpicture.layerList,
            // 当前层位
            currentLayer: this.rootScope.cloudpicture.currentLayer || { id: 3 },
            // 彩图
            colorGraph: '',
            // 图例
            legend: '',
            // 工区类型
            workAreaType:this.rootScope.currentWorkAreaClassify.workAreaType
        };
        this.currentLayerId=this.rootScope.cloudpicture.currentLayer?this.rootScope.cloudpicture.currentLayer.id:''
        // 是否使用新设备(工区列表下可查)
        this.mtype= this.rootScope.currentWorkAreaClassify.mtype
        this.url=`http://218.2.99.139:15710/?workArea=${this.paveCloudPicture.workAreaId}&engineerId=${this.paveCloudPicture.engineerId}&layer=${this.currentLayerId}&isHistory=realTime&materialType=${this.paveCloudPicture.workAreaType}&machineType=13&time=2021-09-05&getTime=${new Date().getTime()}` 
    }

    $onInit() {
        this.searchCloudPicture()

        setTimeout(()=>{
            if(document.getElementById('iframe')){
                document.getElementById('iframe').src=this.url
            }
        },1000)
    }      
    
    /**
     * 彩图缩放工具
     */
    openPhotoSwipe() {
        var pswpElement = document.querySelectorAll('.pswp')[0];

        // build items array
        var items = [
            {
                src: this.pave_cloud_picture_response.colorGraphMax,
                w: this.pave_cloud_picture_response.colorGraphWidthMax,
                h: this.pave_cloud_picture_response.colorGraphHeightMax
            }
        ];

        // define options (if needed)
        var options = {
            index: 0
        };

        var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    }

    /**
     * 获取摊铺彩图和图例
     */
    searchCloudPicture() {
        if (this.searchCloudPictureParamWrong(this.paveCloudPicture)) {
            this.requestError = true
            return
        }

        let params = {
            // 摊铺只有温度离析有彩图信息，所以指标字段直接写死为温度离析
            standard: 'tempSeg',
            // 工程
            engineerId: this.paveCloudPicture.engineerId,
            // 标段
            sectionId: this.paveCloudPicture.sectionId,
            // 工区
            workAreaId: this.paveCloudPicture.workAreaId,
            // 当前被选中层位
            layerId: this.paveCloudPicture.currentLayer.id,
            //
            category: this.paveCloudPicture.standard,
            // 时间
            datePart: dateFormat(new Date(), 'yyyy-MM-dd'),
            timeStart: '00:00',
            timeEnd: '23:59'
        };
        this.systemService.fetchPaveCloudPicture(params, this.materialType).then(res => {
                if (res == null) {
                    this.requestError = true
                    return;
                }
                this.pave_cloud_picture_response = res;
                this.paveCloudPicture.colorGraph = res.colorGraphMax;
                this.paveCloudPicture.legend = res.legend;
            }).catch(res=>{
                this.requestError = true
        })
    }

    /**
     * 参数检查
     * @param terms
     * @returns {boolean}
     */
    searchCloudPictureParamWrong(param) {
        return param == null || param.engineerId == null || param.sectionId == null || param.workAreaId == null
            // || param.currentLayer == null || param.currentLayer.id == null || param.standard == null
    }



    /**
     * get和set方法
     * @returns {*}
     */
    get currentLayer() {
        return this.paveCloudPicture.currentLayer;
    }

    set currentLayer(curLayer) {
        if (curLayer != null && curLayer.id != this.paveCloudPicture.currentLayer.id) {
            this.paveCloudPicture.currentLayer = curLayer;
            this.searchCloudPicture();
        }
    }
    /**
     * 返回上级
     */
    goBack(){
        console.log('in')
        history.back();
    }
}

LqPaveRealTimeCloudPictureCtrl.$inject = ['$scope', "$rootScope", "$interval", "systemService"]
