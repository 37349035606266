/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-08-31 10:05:02
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-09-07 16:30:09
 * @FilePath: \roadcqc-mobile\src\components\warning\warningProcess.ctrl.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import '../../assets/css/warningProcess.css'

export default class warningProcess {
    constructor($stateParams, warningService, $interval,$scope) {
        //this.level = $stateParams.level
        this.interval = $interval
        this.scope = $scope
        this.service = warningService;
        this.detail = $stateParams
        this.warningId = this.detail.id
        this.moduleType = this.detail.moduleType
        this.engid = this.detail.engid
        this.data = {}

        this.checkWarning = false
        this.inputObj = {
            Misfire: 0,
            close: 2
        }
        this.warningfileList = []
        this.showImage = ""
        this.fileType=""
        this.showImg=false
    }

    $onInit() {
        this.detailWarning()
        // this.startTimer()
    }

    $onDestroy() {
        console.log('realTime   onDestry')
        this.interval.cancel(this.timer)
    }

    startTimer() {
        // let that = this
        this.timer = this.interval(() => {
            this.uploadAccessory()
        }, 1000)
    }


    detailWarning() {
        if (this.warningId == null) {
            return;
        }
        this.service.warningDetail(this.warningId, this.moduleType).then(res => {
            this.data = res
            if(this.data.accessory){
                let arr=this.data.accessory.split('|')
                this.warningfileList=arr.map(item=>{
                    let path=item.split('/')
                    item={
                        fileName:path[path.length-1],
                        filePath:item,
                        type:path[path.length-1].split('.')[1]
                    }
                    return item
                })
            }
            this.inputObj={
                programme: this.data.solution,
                Misfire: Number(this.data.wrongSend),
                close: 1,
                remark: this.data.remarks
            }
        });
    }
    checkImage(item){
        this.showImage =this.data.imageUrlPrex+item.filePath
        this.fileType=item.type
        this.showImg=true
        if(this.fileType=='pdf' || this.fileType=='doc' || this.fileType=='docx'){
            setTimeout(()=>{
                document.getElementById('iframe').src=this.showImage
            },400)
        }
        // console.log(this.showImage)
    }
    // 提交
    submitInputObj() {
        let accessory=this.warningfileList.map(item=>{
            return item.filePath
        })
        let params={
            id:this.warningId,
            solution:this.inputObj.programme,
            accessory:accessory.join('|'),
            wrongSend:this.inputObj.Misfire,
            warningStatus:this.inputObj.close,
            remarks:this.inputObj.remark,
        }
        return this.service.submitWarning(params).then(res => {
            history.back();
        });
    }

    uploadAccessory() {
        let FileList = document.querySelector('input[type=file]').files
        if (FileList.length > 0) {
            for (let index = 0; index < FileList.length; index++) {
                let file = FileList[index]
                let formData = new FormData()
                formData.append('file', file);
                formData.append('engid', this.engid);
                // this.service.uploadFile(this.engid,formData).then(res => {
                //     this.data = res
                //     console.log(res)
                // });
                let xhr = new XMLHttpRequest;
                /*初始化HTTP请求参数(请求方式,url,是否同步)*/
                xhr.open('post', '/api/index/v2/upload/file');
                /*
                xmlhttp.setRequestHeader("Content-type","application/x-www-form-urlencoded");
                设置请求头的contentType
                */
                // 监听上传进度
                // xhr.upload.onprogress = function(ev) {
                //     var percent = (ev.loaded / ev.total) * 100 + '%';
                //     console.log(percent);
                //     progress.style.width = percent;
                // }
                xhr.send(formData);
                xhr.onreadystatechange = ()=> {
                    //status状态为200 表示请求成功，readystate为4表示对象传递完毕或者准备就绪 
                    if (xhr.readyState == 4 && xhr.status == 200) {
                        let data=JSON.parse(xhr.response)
                        let arr=data.data.filePath.split('/')
                        let obj={
                            fileName:arr[arr.length-1],
                            filePath:data.data.filePath,
                            type:arr[arr.length-1].split('.')[1]
                        }
                        this.warningfileList.push(obj)
                        this.scope.$apply()
                    }
                }
            }
        } else {
            console.log('当前无文件上传')
        }
    }
    splitFile(item){
        this.warningfileList= this.warningfileList.filter((el)=>{
            return item.filePath!=el.filePath
        })
    }
}

warningProcess.$inject = ['$stateParams', 'warningService', '$interval' , '$scope']