/**
 * 入口文件
 * node的模块管理约定是
 * 如果导入文件目录下有 package.json，就根据它的 main 字段找到 js 文件。如果没有 package.json，那就默认取文件夹下的 index.js。
 *
 */
//ng相关
// import angular from 'angular'; //CDN
import uiRouter from '@uirouter/angularjs';
// import 'ng-dialog'

//mobile-angular-ui package.json  配置有误 只能手工引入
// import 'mobile-angular-ui/dist/css/mobile-angular-ui-base.min.css'; 改为CDN
// import 'angular-ui-bootstrap'  // CDNs

//css
import './assets/css/app.css';
import './assets/css/realtime.css'


//app
import components from './components';
import services from './services'
import pages from './pages'
// import "oclazyload" CDN


let dependency = [
    'oc.lazyLoad',
    uiRouter,
    'ngDialog',
    components,
    services,
    pages,
]


angular.module('road', dependency)
    .config(ngDialogProvider => {
        ngDialogProvider.setDefaults({
            template: 'dialog_template',
            plain: false,
            showClose: true,
            closeByDocument: true,
            closeByEscape: true,
            appendTo: false
        })
    })
    .config(($urlRouterProvider, $locationProvider, $stateProvider) => {
        $locationProvider.html5Mode(true);
        $urlRouterProvider.otherwise('/404');
        $stateProvider.state('notFound', {
            url: '/404',
            template: require('./pages/404/404.html')
        })
    })
    .config(($httpProvider) => {
        $httpProvider.defaults.withCredentials = true;
        console.log($httpProvider)
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }
        $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
    })
    .config(['mapScriptServiceProvider', (provider) => {
        provider.setKey('N0YK3AjiWPs3KWOBGZ6piVwizTYfbSc6');
    }])
    // .config(function($sceDelegateProvider) {
    //     $sceDelegateProvider.resourceUrlWhitelist([
    //         // Allow same origin resource loads.
    //         'self',
    //         // Allow loading from our assets domain.  Notice the difference between * and **.
    //         "*/**"
    //     ]);
    // })
    .run(($transitions, $window, $state, $http, $location, $rootScope) => {
        $transitions.onSuccess({}, (transition) => {
            //路由跳转时更改标题
            let title = transition.to().title;
            if (title) {
                if (title instanceof Function) {
                    title = title.call(transition.to(), transition.params());
                }
                $window.document.title = title;
            }
        })
        //获取接口地址


        let user, pass, pageFlag;
        if ($location.$$search != null && $location.$$search.username != null) {
            user = $location.$$search.username;
            pass = $location.$$search.password;
            pageFlag = $location.$$search.pageFlag;
        } else {
            let token = $location.$$hash
            if (token.length > 1) {
                let array = token.split("&");
                if (array.length !== 2) {
                    console.log('url参数错误')
                    return
                }
                // 正式环境用
                user = array[0]
                pass = array[1]
            }
        }
        // console.log($location);
        //测试环境用
        //user = 'admin'
        //pass = 'jstriadmin1970'
        // user = 'zjhj'
        // pass = '123456'
        //登录
        $rootScope.loading = true

        $http.post('/api/sso/login', `username=${user}&password=${pass}`, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(res => {
            $rootScope.loading = false
            if (res.data && res.data.code === 0) {
                console.log('login success')
                $rootScope.loginuser = user
                // 暂时给需要的用户看指定的数据，临时的，后面不用
                sessionStorage.setItem('userName', res.data.loginName)
                if (pageFlag) {
                    $state.go(pageFlag)
                } else {
                    $state.go('home')
                }

            } else {
                console.log('login failed')
                $state.go('notFound')
            }
        })
    })
