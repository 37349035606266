import lqPage from './lq'
import swPage from './sw'
import snPage from './sn'
import common from './common'
import homeCtrl from './home.ctrl'
import project from './project'
import '_lib/photoswipe-required'
import {ngBaiduMap} from 'angular-baidu-map'

export default angular.module('control', [
    lqPage,
    swPage,
    snPage,
    common,
    ngBaiduMap,
    project
 ])
    .config(function ($stateProvider) {
        $stateProvider
            .state('home', {
                url: '/home',
                template: require('./home.html'),
                controller: homeCtrl,
                controllerAs: 'vm',
                title: '首页'
            })
    })
    .component('mainPage', {
        template: require('./main.html'),
        controller: require('./main.ctrl').default,
        controllerAs: 'vm',
        bindings: {
            materialType: '@',
        }
    }).name
