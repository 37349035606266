/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-08-05 14:01:54
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-09-09 10:49:37
 * @FilePath: \roadcqc-mobile\src\pages\lq\realtime\mixplant.ctrl.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import LqMixplantDashBoardCtrl from "./dashboard.ctrl";

export default class LqRealTimeMixplantCtrl{
    constructor(lqRealTimeService,$rootScope,$interval){
        this.service = lqRealTimeService
        this.rootScope = $rootScope
        this.outPutList = []
        this.interval = $interval
    }
    $doCheck(){
        //当且仅当换拌和楼是查询
        if(this.terms == null){
            return
        }
        if(this.preMixplantId != this.terms.mixplantId){
            this.fetchAnalyzeData()
        }
        this.preMixplantId = this.terms.mixplantId
    }

    $onInit(){
        this.startTimer()
    }

    $onDestroy(){
        console.log('mixplant   onDestry')
        this.interval.cancel(this.timer)
    }



    startTimer(){
        let that = this
        this.timer =  this.interval(function(){
            that.fetchAnalyzeData()
        },30*1000)
    }


    //取30分钟内 最新的一条数据
    fetchAnalyzeData(){
        let terms = this.terms
        if(terms.mixtureId == null){
            return
        }
        let param = {
            'sectionId':terms.sectionId,
            'workAreaId':terms.workAreaId,
            'mixplantId':terms.mixplantId,
            'mixtureId':terms.mixtureId,
            'startDateTime':terms.startDateTime,
            'endDateTime':terms.endDateTime
        }

        this.service.fetchRealTimeOutput(param).then(res=>{
            this.outPutList = res.second
            this.rootScope.dischargeDate = res.first
            this.rootScope.temp = res.temp
            this.rootScope.temp1Level = res.temp1Level
            this.rootScope.mixperiod = res.mixperiod
            this.rootScope.mixPeriodLevel = res.mixPeriodLevel

        })
    }
}

LqMixplantDashBoardCtrl.$inject = ['lqRealTimeService','$rootScope',"$interval"]