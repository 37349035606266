import checkParamReady from '../warning_fn'
export default  class WarningList {
    constructor( warningService,$window){
        this.data = null
        this.window = $window
        this.service = warningService
    }

    $onInit(){
        this.window.localStorage.setItem('warDatePart',this.terms.datePart)
        this.fetchData()
    }

    $doCheck(){


    }

    fetchData(){
        if(!checkParamReady.apply(this)){
            return
        }
        if(this.terms.datePart !== null){
            this.terms.startDate = null;
            this.terms.endDate = null
        }
        let params = {
            datePart: this.terms.datePart,
            materialType:this.terms.materialType,
            sectionId:this.terms.sectionId,
            workAreaId:this.terms.workAreaId
        }
        this.service.currentWarningList(params).then(res => {
            console.log(res);
            this.data = res
        });
    }

    // 返回上级目录
    goBack(){
        history.back();
    }

}

WarningList.$inject = ['warningService','$window']