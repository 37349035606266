/**
 *  转换函数显示调用 替换 angular1.x 中的 filter
 *
 *
 */

/**
 * 对日期进行格式化，
 * @param date 要格式化的日期
 * @param format 进行格式化的模式字符串
 *     支持的模式字母有：
 *     y:年,
 *     M:年中的月份(1-12),
 *     d:月份中的天(1-31),
 *     h:小时(0-23),
 *     m:分(0-59),
 *     s:秒(0-59),
 *     S:毫秒(0-999),
 *     q:季度(1-4)
 * @return String
 * @author yanis.wang@gmail.com
 */
function dateFormat(date, format) {
    if (format === undefined) {
        format = date;
        date = new Date();
    }
    var map = {
        "M": date.getMonth() + 1, //月份
        "d": date.getDate(), //日
        "h": date.getHours(), //小时
        "m": date.getMinutes(), //分
        "s": date.getSeconds(), //秒
        "q": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    format = format.replace(/([yMdhmsqS])+/g, function (all, t) {
        var v = map[t];
        if (v !== undefined) {
            if (all.length > 1) {
                v = '0' + v;
                v = v.substr(v.length - 2);
            }
            return v;
        } else if (t === 'y') {
            return (date.getFullYear() + '').substr(4 - all.length);
        }
        return all;
    });
    return format;
}


// 日期加减
function addDate(date, dadd) {
    date = date.valueOf();
    date = date + dadd * 24 * 60 * 60 * 1000;
    return new Date(date);
}

/**
 * 添加分钟
 * @param date
 * @param num
 * @returns {Date}
 */
function addMinute(date, num) {
    return new Date(date.valueOf() + num * 60 * 1000)
}

/**
 * 桩号转换成友好字符
 * @param value
 * @returns {string}
 */
function pileNo2Str(value) {
    var kkkk = Math.floor(value / 1000);
    var kkk = Math.floor((value % 1000) / 100);
    var kk = Math.floor((value % 100) / 10);
    // 2018-06-13 张总提出: 桩号不要保留小数
    // var k = parseFloat(new Number(value % 10).toFixed(2));
    var k = Math.floor(value % 10);
    // console.log(kkkk+''+kkk+"-"+kk+"-"+k);
    return 'K' + kkkk + '+' + kkk + '' + kk + '' + k
}

/**
 * 判断 series 如果name 是上下限 标准值 将其转成 虚线 不显示圆点
 * @param option
 */
function formatOption(option) {
    let isTemp = false
    if (option.series && option.series.length > 0) {
        option.series.forEach((item, idx) => {
            if (item.name==='水泥用量' || item.name==='水胶比' || item.name=='集料' || item.name=='水用量' || item.name=='矿粉' || item.name=='粉煤灰' || item.name=='添加剂') {
                item.itemStyle = {
                    normal: {
                        color: '#e8b503'
                    }
                }
            }else if (item.name === '标准值' || item.name === '上限' || item.name === '下限'
                || item.name === '上限值' || item.name === '下限值'
                || item.name === '上限、下限'
                || item.name === '轻度、中度、重度') {
                item.symbol = 'none'
                item.lineStyle = {
                    normal: {
                        type: 'dotted',
                    }
                }
                item.itemStyle = {
                    normal: {
                        color: '#CC6666'
                    }
                }
            } else if (item.name === '目标值') {
                item.symbol = 'none'
                item.lineStyle = {
                    normal: {
                        type: 'dotted',
                    }
                }
                item.itemStyle = {
                    normal: {
                        color: '#99CC99'
                    }
                }
            }else if (item.name=='出料温度'){
                isTemp = true
            }else {
                item.itemStyle = {
                    normal: {
                        color: '#e8b503'
                    }
                }
            }
        })
    }
    option.notMerge = true
    if (isTemp){
        option.yAxis.min = yAxisMinIntegerCompute
        option.yAxis.max = yAxisMaxIntegerCompute
    }else{
        option.yAxis.min = yAxisMinCompute
        option.yAxis.max = yAxisMaxCompute
    }
    return option
}


/**
 * 预警列表里面的预警标题
 * @param moduleType
 * @param level
 * @returns {string}
 */
function warningTitle(moduleType, level) {
    let result = moduleTypeCN(moduleType);

    if (level === 1) {
        result = result + "(轻度预警)"
    }
    if (level === 2) {
        result = result + "(一般预警)"
    }
    if (level === 3) {
        result = result + "(严重预警)"
    }
    return result;
}

/**
 * 系统级别的 是 否 转义
 * @param flag
 * @returns {string}
 */
function yesOrNotCN(flag) {
    if (flag === 1) {
        return "是"
    }
    if (flag === 0) {
        return "否"
    }
}

/**
 * 模块类型中文
 * @param moduleType
 * @returns {string}
 */
function moduleTypeCN(moduleType) {
    if (moduleType === 'mixplant') {
        return "拌和"
    }
    if (moduleType === 'transport') {
        return "运输"
    }
    if (moduleType === 'pave') {
        return "摊铺"
    }
    if (moduleType === 'rolling') {
        return "碾压"
    }
    return "";
}

/**
 * 预警程度中文
 * @param level
 */
function warningDegreeCN(level) {
    if (level === 1) {
        return "轻度"
    }

    if (level === 2) {
        return "中度"
    }

    if (level === 3) {
        return "重度"
    }
    return "";
}

/**
 * 预警处理状态中文
 */
function warningProcessStatusCN(status) {
    if (status === 0) {
        return "未处理";
    }

    if (status === 1) {
        return "处理中";
    }

    if (status === 2) {
        return "已处理";
    }

    if (status === -1) {
        return "已过期"
    }
    return "";
}

function echartPileNoTipFormatter(params) {
    let tips = ``
    if (Array.isArray(params)) {
        console.log(params);
        if (params.length>1){
            tips = pileNo2Str(params[0].value[0])+"<br>"
        }
        tips = tips + tooltipFormatter(params)
    }
    tips += ` `
    return tips;
}

function echartPileNoTipFormatterSelf(params) {
    let tips = ``
    if (Array.isArray(params)) {
        if (params.length>1){
            tips = pileNo2Str(params[0].axisValue)+"<br>"
        }
        tips = tips + tooltipFormatterSelf(params)
    }
    tips += ` `
    return tips;
}

function echartTimeTipFormatter(params) {
    // console.log(params);
    let tips = ``
    if (Array.isArray(params)) {
        if (params.length>1){
            tips = params[0].value[0].substring(11)+"<br>"
        }
        tips = tips + tooltipFormatter(params)
    }
    tips += ` `
    return tips;
}

function echartTimeTipFormatterDp(params) {
    // console.log(params);
    let tips = ``
    if (Array.isArray(params)) {
        if (params.length>1){
            tips = params[0].value[0]+"<br>"
        }
        tips = tips + tooltipFormatter(params)
    }
    tips += ` `
    return tips;
}

function echartDayTipFormatter(params) {
    let tips = ``
    if (Array.isArray(params)) {
        if (params.length>1){
            tips = params[0].value[0].substring(0,11)+"<br>"
        }
        tips = tips + tooltipFormatter(params)
    }
    tips += ` `
    return tips;
}

function tooltipFormatter(params){
    let tips = ``
    let index = 0;
    params.forEach(param => {
        if (param.value != undefined && param.value.length > 1) {
            if (param.seriesName =='上限、下限' || param.seriesName =='轻度、中度、重度'){
                let name = param.seriesName.split('、')
                tips += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${param.color}"></span>${name[index]}: ${param.value[1]}${param.value[2]}<br>`
                index = index >= name.length-1 ? 0 : index + 1
            } else{
                tips += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${param.color}"></span>${param.seriesName}: ${param.value[1]}${param.value[2]}<br>`
            }
        }
    })
    return tips;
}

function tooltipFormatterSelf(params){
    let tips = ``
    let index = 0;
    params.forEach(param => {
        if (param.value) {
            if (param.seriesName =='上限、下限' || param.seriesName =='轻度、中度、重度'){
                let name = param.seriesName.split('、')
                tips += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${param.color}"></span>${name[index]}: ${param.value}${param.unit}<br>`
                index = index >= name.length-1 ? 0 : index + 1
            } else{
                tips += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${param.color}"></span>${param.seriesName}: ${param.value}${param.unit}<br>`
            }
        }
    })
    return tips;
}


function groupByRollerType(dataArray) {
    let hash = {}
    let groupArray = []
    let indexStart = 0
    dataArray.forEach((item) => {
        let index = hash[item.rollerType]
        // console.log(item.rollerType);
        if (index != undefined) {
            groupArray[index].push(item)
        } else {
            //初始push
            hash[item.rollerType] = indexStart
            groupArray[indexStart] = []
            groupArray[indexStart].push(item)
            indexStart++
        }
    })
    return groupArray
}

function yAxisMinCompute(value) {
    if (value.min < 0) {
        //最小值为负数
        return value.min;
    }
    let min = value.min - (value.max - value.min) / 5
    return min < 0 ? 0 : min.toFixed(1);
}

function yAxisMinIntegerCompute(value) {
    if (value.min < 0) {
        //最小值为负数
        return value.min;
    }
    let min = value.min - (value.max - value.min) / 5
    return min < 0 ? 0 : min.toFixed(0);
}
/**
 * 这里为什么除以 5 ；因为echarts y轴默认总是显示五个 ticket 或者label
 * 相当于我们将Y轴分成五份，最小值在下，最大值再上，其中整体上下浮动一个份,然后整体缩放7份
 * 例如最小值20 最大值 120 一共显示五分 相当于一分显示 20个值；
 * 现在加上下间距，一共显示7分，相当于一分显示 100/7=14.2分 上下间距=14.2，如果还需要调整间距则 除以5数再乘以一个数
 * 此处相当于 (最大值-最小值)/5 *1
 * 也可以 10 波动图向上浮动 0.5个
 * 这里可能还需要调整，可能某些图标不希望显示小数位，比如温度。
 */
function yAxisMaxCompute(value) {
    let max = value.max + (value.max - value.min) / 5;
    return max.toFixed(1);
}

function yAxisMaxIntegerCompute(value) {
    let max = value.max + (value.max - value.min) / 5;
    return max.toFixed(0);
}

function strIsEmpty(value) {
    if(value == "" || value == null || value == undefined){
        return true
    }else{
        return false
    }
}

export {
    dateFormat,
    pileNo2Str,
    addDate,
    addMinute,
    formatOption,
    warningTitle,
    yesOrNotCN,
    warningProcessStatusCN,
    moduleTypeCN,
    warningDegreeCN,
    echartPileNoTipFormatter,
    echartPileNoTipFormatterSelf,
    echartTimeTipFormatter,
    echartTimeTipFormatterDp,
    echartDayTipFormatter,
    groupByRollerType,
    yAxisMinCompute,
    yAxisMaxCompute,
    strIsEmpty
}
