import checkParamReady from '../warning_fn'
export default  class SumyWarningList {
    constructor( warningService,$window,$rootScope){
        this.theme = 'yemast'
        this.data = null
        this.window = $window
        this.service = warningService
        this.rootScope = $rootScope

    }

    $onInit(){
        console.log(this.terms,"---当天预警参数");
        this.window.localStorage.setItem('warStartDate',this.terms.startDate)
        this.window.localStorage.setItem('warEndDate',this.terms.endDate)

        this.fetchData()
    }
    
    // 6918bug，后台返回数据不符合（已解决）
    fetchData(){
        var param={
            dimension:this.terms.dimension,
            endDate:this.terms.endDate,
            engineerId:this.terms.engineerId,
            // layerId:this.terms.layerId,
            materialType:this.terms.materialType,
            sectionId:this.terms.sectionId,
            startDate:this.terms.startDate,
            workAreaId:this.terms.workAreaId
        }
        this.service.sumWarningList(param).then(res=>{
            this.data = res
            this.rootScope.untreated = res.unProcessCount
            // console.log(this.rootScope);
        });
    }
}

SumyWarningList.$inject = ['warningService','$window','$rootScope']