import {addMinute, dateFormat} from "_services/transformers";

export default class LqRealTimeMainInfo {
    constructor(lqRealTimeService, $scope, $state, $rootScope, $interval) {
        this.service = lqRealTimeService

        this.scope = $scope

        this.state = $state

        this.mixplantList = []

        this._currentMixplant = null
        this._working = false

        this.mixplantIndex = 0

        this.isFirstRequest = true

        this.timeRange = -30

        this._runMixture = null

        this.terms = {
            materialType:'lq'
        }

        this.rootScope = $rootScope

        this.interval = $interval;

        // 视频显示影藏
        this.videoSite1="0"
        this.videoSite2="0"

        // 生产信息-累计拌合/单位小时产量/油石比
        this.data = {}
    }


    $onInit() {
        this.scope.$parent.vm.where = 'rel'
        this.startTimer()
    }

    $onChanges(changes) {
        let workArea = changes.currentWorkArea.currentValue
        if (workArea != null) {
            let section = workArea.section
            this.terms.engineerId = workArea.engineer.id
            if(workArea.chainList && workArea.chainList.length>0){
                this.terms.chainValueList = workArea.chainList.map(item=>{
                    return item.value
                })
                // console.log(this.terms)
            } 
            this.terms.sectionId = section.id
            this.terms.workAreaId = workArea.id
            this.terms.isHighConfOnPave = workArea.highLowConfigTypeOnPave == 0  ;
            this.terms.isHighConfOnRoll = workArea.highLowConfigTypeOnroll == 0  ;
            this.fetchMixplantList()

            this.terms.startPileNo=workArea.startPileNo
            this.terms.endPileNo=workArea.endPileNo
        } else {
            this.mixplantList = []
            this.currentMixplant = null
        }

        if(workArea != null){
            this.videoSite1=workArea.videoSite1
            this.videoSite2=workArea.videoSite2
        }
        
    }

    $onDestroy() {
        console.log('realTime   onDestry')
        this.interval.cancel(this.timer)
    }

    startTimer() {
        let that = this
        this.timer = this.interval(function () {
            that.fetchMixplantList()
        }, 30 * 1000)
    }

    get dischargeDate() {
        return this.rootScope.dischargeDate
    }

    get temp(){
        let  temp = this.rootScope.temp
        temp = String(temp).indexOf('.')>0?temp.toFixed(1):temp
        return temp
    }

    get temp1Level(){
        return this.rootScope.temp1Level
    }

    get mixperiod(){
        return this.rootScope.mixperiod
    }

    get mixPeriodLevel(){
        return this.rootScope.mixPeriodLevel
    }

    set currentMixplant(m) {
        this._currentMixplant = m
        if (m != null) {
            this._working = m.working
            this.terms.mixplantId = m.mixplant.id
            this.terms.todayWorking=this._currentMixplant.todayWorking
        }
        if (m != null && m.mixture != null) {
            this.runMixture = m.mixture
        } else {
            this.runMixture = null
        }
    }

    get currentMixplant() {
        return this._currentMixplant
    }
    set working(m) {
        this._working = m
    }

    get working() {
        return this._working
    }

    set runMixture(m) {
        this._runMixture = m
        if (m != null) {
            this.terms.mixtureId = m.id
        }
        // 生产信息-累计拌合/单位小时产量/油石比
        this.fetchAnalyzeData()
        return m
    }

    get runMixture() {
        return this._runMixture
    }

    fetchMixplantList() {
        let workArea = this.currentWorkArea;
        if(workArea==null){
            return
        }
        let now = new Date()
        let before = addMinute(now, this.timeRange)

        this.terms.startDateTime = dateFormat(before, 'yyyy-MM-dd hh:mm:00')
        this.terms.endDateTime = dateFormat(now, 'yyyy-MM-dd hh:mm:00')
        this.mixplantList = []
        this.service.fetchMixplantList(workArea.id, this.timeRange).then(resList => {
            this.mixplantList = resList
            this.rootScope.cloudpicture.currentLayer = resList[0].layer;
            if (Array.isArray(resList) && resList.length > 0) {
                if (this.isFirstRequest) {
                    //第一次请求是 默认选择生产的拌和楼
                    this.mixplantIndex = 0
                    let showMixplant = resList[0];

                    for (let i = 0; i < resList.length; i++) {
                        if (resList[i].mixture != null) {
                            showMixplant = resList[i]
                            this.mixplantIndex = i
                        }
                    }
                    this.isFirstRequest = false
                    this.currentMixplant = showMixplant
                    this.working = this.currentMixplant.working
                } else {
                    //拌和楼数量变化了
                    if (this.mixplantIndex < 0 || this.mixplantIndex >= resList.length) {
                        this.mixplantIndex = 0
                    }
                    //保持监控同一个拌和楼
                    this.currentMixplant = resList[this.mixplantIndex]
                    this.working = this.currentMixplant.working
                }

            }
            // this.loading = false;
        })
    }

    // 生产信息-累计拌合/单位小时产量/油石比
    fetchAnalyzeData(){
        let workArea = this.currentWorkArea;
        if(workArea==null){
            return
        }
        let terms = this.terms
        let now = new Date()
        let today = dateFormat(now,'yyyy-MM-dd')
        let param = {
            engineerId:terms.engineerId,
            sectionId:terms.sectionId,
            workAreaId:terms.workAreaId,
            mixplantId:terms.mixplantId,
            mixtureId:terms.mixtureId,
            startDate:today,
            endDate:today
        }
        this.service.fetchLabelDashBoard(param).then(res=>{
            this.data = res
            // console.log(res)
            this.data.mixplantId=this.terms.mixplantId
        })
    }


    /**
     * ←从右往左滑 下一个 拌和楼
     */
    nextMixplant() {
        console.log('next mix')
        let size = this.mixplantList.length
        let next = ++this.mixplantIndex
        if (next >= size) {
            next = size - 1
            this.mixplantIndex = next
        }
        this.currentMixplant = this.mixplantList[next]
    }

    /**
     *  → 从左滑往右 前一个 拌和楼
     */
    preMixplant() {
        console.log('pre mix')
        let pre = --this.mixplantIndex
        if (pre < 0) {
            pre = 0
            this.mixplantIndex = pre
        }
        this.currentMixplant = this.mixplantList[pre]
    }

    /**
    * 跳转到视频监控
    */
    toLqCheckVideo() {
        this.state.go('lqCheckVideoAfter')
    }

}

LqRealTimeMainInfo.$inject = ['lqRealTimeService', '$scope','$state', "$rootScope", "$interval"]