import {addDate, dateFormat} from "_services/transformers";

export default class LqTraCurCtrl {
    constructor(ngDialog, $rootScope, transportService, $sce,$state) {
        this.ngDialog = ngDialog
        this.state = $state;
        this.sce = $sce
        this.rootScope = $rootScope;
        this.service = transportService
        this.terms = {}
        this.data = {}
        this.dataList = []

        this.currentByLoading = false
        this.historyByLoading = false

        this.noDate = false

    }

    $onChanges(changes) {
        var vm=this;
        this.rootScope.$on("datePartChange",function(event){
            vm.terms.datePart = vm.rootScope.datePart
            vm.fetchData()
        });
        this.rootScope.$on("startDateChange",function(event){
            vm.terms.startDate = vm.rootScope.startDate
            vm.fetchData()
        });
        this.rootScope.$on("endDateChange",function(event){
            vm.terms.endDate = vm.rootScope.endDate
            vm.fetchData()
        });
        let workArea = changes.currentWorkArea.currentValue
        if (workArea != null) {
            let section = workArea.section
            this.terms.sectionId = section.id
            this.terms.workAreaId = workArea.id
            this.terms.engineerId = workArea.engineer.id
            this.getDefaultForm()
        } else {
            this.terms.sectionId = null
            this.terms.workAreaId = null
        }
    }

    $onInit() {
        let state = this.state.current.name
        this.terms.materialType = this.materialType
        this.terms.moduleType = 'transport'
        this.dataList = null;
        if (this.byWhat == 'byDay') {
            this.isHistory = true
            this.historyByLoading = true;
            this.terms.startDate = this.rootScope.startDate
            this.terms.endDate = this.rootScope.endDate
        } else {
            this.isHistory = false
            this.currentByLoading = true;
            this.terms.datePart = this.rootScope.datePart
        }
        if(state.indexOf('history') > 0){
            this.rootScope.where = 'his'
        }else if(state.indexOf('current') > 0){
            this.rootScope.where = 'cur'
        }else if(state.indexOf('summary') > 0){
            this.rootScope.where = 'sum'
        }else{
            this.rootScope.where = 'rel'
        }
        // this.getDefaultForm()
    }


    getDefaultForm() {
        this.service.getDefaultForm(this.terms).then((res) => {
            if (res != undefined && res.endDate != undefined) {
                if (res.endDate == null || res.endDate == '') {
                    res.endDate = dateFormat(new Date(), 'yyyy-MM-dd');
                }
                if(!this.rootScope.moduleTypeChange){
                    this.rootScope.startDate = dateFormat(addDate(Date.parse(res.endDate), -7), 'yyyy-MM-dd')
                    this.rootScope.startDateCurrent = new Date(this.rootScope.startDate)
                    this.rootScope.endDate = res.endDate
                    this.rootScope.endDateCurrent = new Date(this.rootScope.endDate)
                    this.rootScope.datePart = res.endDate
                    this.rootScope.datePartCurrent = new Date(this.rootScope.datePart)
                }
                this.terms.startDate =  this.rootScope.startDate
                this.terms.endDate = this.rootScope.endDate
                this.terms.datePart = this.rootScope.datePart
            }
            this.fetchData()
        })
    }

    paramWrong(terms) {
        return terms.workAreaId == null || terms.materialType == null
    }

    fetchData() {
        this.dataList = null;
        this.rootScope.moduleTypeChange = false
        let term = this.terms
        let param = {
            'sectionId': term.sectionId,
            'workAreaId': term.workAreaId,
            materialType: this.materialType
        }
        if (this.isHistory) {
            param.startDate = term.startDate
            param.endDate = term.endDate
        } else {
            param.startDate = term.datePart
            param.endDate = term.datePart
        }
        if (this.paramWrong(param)) {
            this.historyByLoading = false
            this.currentByLoading = false
            this.noDate = true
            return
        }
        this.service.getCurrentCollect(param).then(res => {
            this.data = res
        })
        if (this.byWhat === 'byDay') {
            this.service.getHistoryDetail(param).then(res => {
                this.dataList = res
            }).finally(res => {
                this.historyByLoading = false
                this.noDate = this.dataList == null || this.dataList.length == 0
            })
        } else {
            this.service.getCurrentDetail(param).then(res => {
                this.dataList = res
            }).finally(res => {
                this.currentByLoading = false
                this.noDate = this.dataList == null || this.dataList.length == 0
            })
        }
    }

    showGps(t) {
        var that = this ;
        console.log(t)
        var param={
            engineerId:t.engineering.id,
            workAreaId:t.workAreaId,
            tageCode:t.transporter.tageCode,
            startTime:t.endLoadingDate,
            endTime:t.beginSpreadingDate,
        }
        this.service.getTrackV2(t).then((res) => {
            console.log('res',res);
            console.log('param',param);
            let u = "http://218.2.99.139:15780/trackReplay.html?engineerId="+param.engineerId+"&workAreaId="+param.workAreaId+"&tranType=4&tageCode="+param.tageCode+"&begingLoadingDate="+param.startTime+"&begingUnloadDate="+param.endTime+"&isHistory=realTime1";
            console.log('u',u);
            if (res && res.tageCode && param.endTime) {
                let url = u ;
                // this.ngDialog.open({
                //     template: 'track_template',
                //     data: {url: this.sce.trustAsResourceUrl(url)}
                // })
                //todo 这里可以跳转到新的页面 并且传参数进去
                that.toSwCheckVideo(url);
            } else {
                this.ngDialog.open({
                    template: 'no_GPS_template'
                });
            }
        })
    }

    /**
     * 跳转到视频监控
     */
    toSwCheckVideo(url) {
        this.state.go('swCheckVideoAfter',{'url':url,'title':'运输轨迹'})
    }
    gotoTrack(t) {
        let url = this.rootScope.backendPath + "/transport/trackReplay?transportDataId=" + t.id
        this.ngDialog.open({
            template: 'track_template',
            data: {url: url}
        })
    }

    get transportUrl() {
        return this.sce.trustAsResourceUrl(this.url);
    }
}

LqTraCurCtrl.$inject = ['ngDialog', '$rootScope', 'transportService', '$sce','$state']