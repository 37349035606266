/**
 *
 */

import { addDate, addMinute, dateFormat, pileNo2Str } from "_services/transformers";

export default class LqRealTimePaveInfo {
    constructor(lqRealTimePaveService, $interval, $state, systemService, $rootScope) {
        this.service = lqRealTimePaveService

        this.interval = $interval

        this.state = $state

        this.systemService = systemService

        this.rootScope = $rootScope

        this.info = {}

        this.rollers = []

        this.pavers = []

        this.hasWork = false

        this.currentPileNoStr = ''

        //桩号范围
        this.paveMinPileNo = ''
        this.paveMaxPileNo = ''

        this.col = 4

        //30 *6 分 内
        this.timeRange = -60 * 5
        //20 秒
        this.period = 20 * 1000

        // 主用于彩图
        this.rootScope.cloudpicture = {}

        // 判断是否为低配
        this.isDp = ''

        //临时存储变量给特定的用户显示摊铺厚度
        this.flag = false

        // 视频显示影藏
        this.videoSite1 = "0"
        this.videoSite2 = "0"


        this.mtype = ""

        // 停机次数
        this.paveStopCount = {}
        this.paveDis = {}
        this.rollerDis = {}
        this.showPaveDis = true
    }

    $doCheck() {
        // console.log(this.terms,'terms');
        //工区变化时重新查询
        if (this.preWorkAreaId !== this.terms.workAreaId) {
            this.fetchWorkAreaDp()
            // 保证执行顺序，否则cloudpicture有可能会被赋值为{}对象
            this.fetchPaveLayers().then(res => this.fetchRollerType());

            // 获取工区摊铺机
            this.getPavesByWorkAreaId()
        }
        this.preWorkAreaId = this.terms.workAreaId

        let workArea = this.rootScope.currentWorkAreaClassify
        if (workArea != null) {
            this.videoSite1 = workArea.videoSite1
            this.videoSite2 = workArea.videoSite2

            this.mtype = workArea.mtype
        }
    }

    $onInit() {
        this.startTimer()
    }

    $onDestroy() {
        console.log('paver   onDestry')
        this.interval.cancel(this.timer)
    }


    startTimer() {
        let that = this
        this.timer = this.interval(function() {
            that.fetchWorkAreaDp()
        }, this.period)
    }

    fetchData() {
        if (!this.paramWrong(this.terms)) {
            return
        }
        let terms = this.terms
        let now = new Date()
        let param = {
            'materialType': 'lq',
            sectionId: terms.sectionId,
            workAreaId: terms.workAreaId,
            startDateTime: dateFormat(addMinute(now, this.timeRange), 'yyyy-MM-dd hh:mm:00'),
            // startDateTime:'2020-01-01 12:12:00',
            endDateTime: dateFormat(now, 'yyyy-MM-dd hh:mm:00')
        }
        // 8065bug
        this.service.fetchOnlineMachineInfo(param).then(info => {
            console.log(info);
            //临时给指定用户展示摊铺厚度，后期不用
            let userName = sessionStorage.getItem('userName')
            let nameArr = ['admin', '330zzl', '330zp', '330csq', '330zjz', 'sjkxb', 'sjksjw', 'sjkzp', '330yw']
            if (nameArr.indexOf(userName) == -1) {
                this.flag = false
            } else {
                this.flag = true
            }

            this.info = info;

            if (info.pavers && info.pavers.length > 0) {
                this.pavers = info.pavers
            } else {
                this.pavers = []
            }

            if (info.rollers && info.rollers.length > 0) {
                this.rollers = info.rollers.map(item => {
                    item.pileNo = pileNo2Str(item.pileNo)
                    return item
                })
            } else {
                this.rollers = []
            }


            this.hasWork = info.hasWork

            if (info.paverAvgInfo && JSON.stringify(info.paverAvgInfo) != '{}') {
                this.paverAvgInfo = info.paverAvgInfo
            } else {
                this.paverAvgInfo = {}
            }

            this.currentPileNoStr = info.currentPileNoStr
            this.paveMinPileNo = info.paveMinPileNo
            this.paveMaxPileNo = info.paveMaxPileNo
            this.col = info.col
        })
    }

    // 停机次数
    stopCount() {
        let now = new Date()
        let terms = this.terms
        let params = {
            engid: terms.engineerId,
            workid: terms.workAreaId,
            workDate: dateFormat(now, 'yyyy-MM-dd')
        }
        this.service.stopCount(params).then(res => {
            // console.log(res)
            if (res.data) {
                this.paveStopCount = res.data.paveStopCount
                if (res.data.paveDis) {
                    this.paveDis = res.data.paveDis.distance
                }
                if (res.data.rollerDis) {
                    this.rollerDis = res.data.rollerDis.distance
                }

            }
        })
    }


    paramWrong(terms) {
        if (terms == null) {
            return false
        }
        if (terms.workAreaId == null || terms.sectionId == null) {
            return false
        }
        return true
    }


    /**
     * 获取层位信息
     */
    fetchPaveLayers() {
        if (!this.paramWrong(this.terms)) {
            return null;
        }
        // console.log('获取层位信息')
        let terms = {};
        terms.dp = false;
        terms.engineerId = this.terms.engineerId;
        terms.materialType = this.terms.materialType;
        terms.sectionId = this.terms.sectionId;
        terms.workAreaId = this.terms.workAreaId;
        terms.datePart = dateFormat(new Date(), 'yyyy-MM-dd')
        return this.systemService.fetchPaveDefaultFilter(terms).then(res => {
            // 先清除之前获取的层位信息
            this.rootScope.cloudpicture = {}
            if (res.length > 0) {
                this.rootScope.cloudpicture.layerList = res
                this.currentLayer = res[0]
            } else {
                this.currentLayer = {}
            }
            // this.rootScope.cloudpicture.currentLayer = this.currentLayer;
            this.rootScope.cloudpicture.engineerId = this.terms.engineerId;
            this.rootScope.cloudpicture.sectionId = this.terms.sectionId;
            this.rootScope.cloudpicture.workAreaId = this.terms.workAreaId;
        })
    }

    fetchRollerType() {
        // console.log('获取层位信息')
        return this.systemService.fetchRollerTypeAndLayerFromRealTime(this.terms.workAreaId, 'lq').then(res => {
            // console.log(res)
            this.rootScope.cloudpicture.rollerTypeList = res.rollerTypeList
            this.rootScope.cloudpicture.rollLayerList = res.layerList
            if (res.layerList != null && res.layerList.length > 0) {
                this.rootScope.cloudpicture.currentRollLayer = res.layerList[0];
            } else {
                this.rootScope.cloudpicture.currentRollLayer = {}
            }
            if (res.rollerTypeList != null) {
                this.rootScope.cloudpicture.curRollerType = res.rollerTypeList[0]
            }


        })
    }

    fetchWorkAreaDp() {
        return this.service.fetchWorkAreaDp(this.rootScope.currentWorkAreaClassify.id).then(res => {
            window.sessionStorage.setItem("isDp", res)
            this.isDp = res
            if (this.isDp == false) {
                this.fetchData()
                this.stopCount()
            } else {
                this.fetchWorkAreaDpDetails()
            }
        })
    }

    fetchWorkAreaDpDetails() {
        if (!this.paramWrong(this.terms)) {
            return
        }
        let terms = this.terms
        let param = {
            workAreaId: terms.workAreaId,
        }
        this.service.fetchWorkAreaDpInfo(param).then(info => {
            console.log(info)

            this.info = info;

            this.pavers = info.paver

            this.hasWork = info.work

            this.rollerNum = info.roller.online
            this.rollerAvgInfo = info.roller.deviceList.map(item => {
                item.show = false
                item.style = {
                    left: Math.random() * 80 + '%',
                    top: Math.random() * 80 + '%'
                }
                return item
            })
            this.paverAvgInfo = info.paver.deviceList.map((item, index) => {
                item.show = false
                item.style = {
                    top: index * 80 + 'px'
                }
                return item
            })

            this.middle = info.roller.middle

            // console.log(this.rollerAvgInfo)

            // this.currentPileNoStr = info.currentPileNoStr
            // this.paveMinPileNo=info.paveMinPileNo
            // this.paveMaxPileNo=info.paveMaxPileNo
            // this.col = info.col
        })
    }

    // 获取工区摊铺机
    getPavesByWorkAreaId() {
        let params = {
            workAreaId: this.terms.workAreaId,
            materialType: this.terms.materialType
        }
        this.service.getPavesByWorkAreaId(params).then(res => {
            if (res.data.length > 1) {
                this.showPaveDis = true
            } else {
                this.showPaveDis = false
            }
        })
    }

    /**
     * 跳转到摊铺云图
     */
    toPaveRealTimeCloudPicture() {
        this.state.go('lqPaveRealTimeCloudPicture');
    }
    /**
     * 跳转到碾压云图
     */
    toRollRealTimeCloudPicture() {
        this.state.go('lqRollRealTimeCloudPicture')
    }
    /**
     * 跳转到视频监控
     */
    toLqCheckVideo() {
        this.state.go('lqCheckVideo')
    }
}
LqRealTimePaveInfo.$inject = ['lqRealTimePaveService', '$interval', '$state', 'systemService', '$rootScope']