function checkParamReady(){

    if(this.terms == null){
        return false;
    }

    if(this.terms.sectionId === null){
        return false;
    }
    if(this.terms.workAreaId === null){
        return false;
    }
    if(this.terms.materialType === null){
        return false;
    }
    if(this.terms.moduleType === null){
        return false;
    }

    return true;
}
export {
    checkParamReady
}
export default checkParamReady