/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-08-05 14:01:54
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-09-07 16:39:19
 * @FilePath: \roadcqc-mobile\src\components\warning\warning_detail.ctrl.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import '../../assets/css/warningProcess.css'
export default  class WarningDetail {
    constructor($stateParams,warningService){
        //this.level = $stateParams.level
        this.service = warningService;
        this.detail = $stateParams
        this.warningId = this.detail.id
        this.moduleType = this.detail.moduleType
        this.data = {}

        this.checkWarning=false
        this.warningfileList=[]
        this.showImage = ""
        this.fileType=""
        this.showImg=false
    }

    $onInit(){
        // console.log(this.detail)
        // console.log(this.warningId)
        // console.log(this.moduleType)
        this.detailWarning()
    }


    detailWarning(){
        if(this.warningId == null){
            return;
        }
        this.service.warningDetail(this.warningId,this.moduleType).then(res=>{
            this.data = res
            this.data.wrongSend=Number(this.data.wrongSend)
            this.data.warningStatus=Number(this.data.warningStatus)
            if(this.data.accessory){
                let arr=this.data.accessory.split('|')
                this.warningfileList=arr.map(item=>{
                    let path=item.split('/')
                    item={
                        fileName:path[path.length-1],
                        filePath:item,
                        type:path[path.length-1].split('.')[1]
                    }
                    return item
                })
            }
        });
    }

    checkImage(item){
        this.showImage =this.data.imageUrlPrex+item.filePath
        this.fileType=item.type
        this.showImg=true
        if(this.fileType=='pdf' || this.fileType=='doc' || this.fileType=='docx'){
            setTimeout(()=>{
                document.getElementById('iframe').src=this.showImage
            },400)
        }
    }
}

WarningDetail.$inject = ['$stateParams','warningService']