export default class ProjectCtrl {
    constructor($state, $stateParams, projectService) {
        this.service = projectService

        this.projectId = $stateParams.id

        this.sectionId = $stateParams.sectionId

        this.name = $stateParams.name

        this.opts = {}

        this.info = {}

        this.sectionInfoList = []
    }

    $onInit() {

        if (this.projectId != null) {
            this.fetchData()
        }

        this.opts = {
            centerAndZoom: {
                longitude: 121.51606,
                latitude: 31.244604,
                zoom: 16
            }
        }

        this.markerOpts = {
            offset: {
                width: 0,
                height: 0
            },
            icon: {
                url: '/assets/img/markers.png',
                size: {
                    width: 30,
                    height: 30
                }
            }
        };

        this.showWindow = function(marker) {
            let title="<span style='border: 2px solid #E4E4E4;border-radius:3px;padding:6px 10px;background-color: #FFFFFF;color: #949494;transform:translateX(-50%);"+
                "position:absolute;left: 50%;'>"+this.name+"</span>"
            var label = new BMap.Label(title,{offset:new BMap.Size(7,-35)});
            label.setStyle({
                maxWidth:"none",
                border: "0px",
            })
            marker.setLabel(label);
        };

        this.location = {
            longitude: 121.51606,
            latitude: 31.244604
        }
    }

    fetchData() {
        this.service.fetchProjectInfo({
            "engineerId": this.projectId,
            "sectionId": this.sectionId
        }).then(res => {
            this.info = res
            this.sectionInfoList = res.sectionList
            if (res.baiduPoint != null) {
                let point = res.baiduPoint.split(',')
                if(point.length === 2){
                    this.opts = {
                        centerAndZoom: {
                            longitude: point[0],
                            latitude: point[1],
                            zoom: 16
                        }
                    }
                    this.location =  {
                        longitude : point[0],
                        latitude : point[1]
                    }
                }
            }
        })
    }
}

ProjectCtrl.$inject = ['$state', '$stateParams', 'projectService']