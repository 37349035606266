// import 'angular-touch' //CDN
export default angular.module('control.sn.realTime', [
    'ngTouch',
])
    .service('snRealTimeService',require('./service/realTime.service').default)
    .config(function ($stateProvider) {
        $stateProvider
            .state('sn.realTime', {
                url: '/realTime',
                template: '<sn-real-main current-work-area="vm.currentWorkArea" ></sn-real-main>',
                title:'水泥实时'
            })

    })
    .component('snRealMain', {
        template: require('./realTime.html'),
        controller: require('./realTime.ctrl').default,
        controllerAs: 'vm',
        bindings: {
            currentWorkArea: '<',
        }
    })
    .component('snRealTimeMix', {
        template: require('./mixplant.html'),
        controller: require('./mixplant.ctrl').default,
        controllerAs: 'vm',
        bindings: {
            terms: '<',
        }
    })
    .component('snRealTimeDash', {
        template: require('./dashboard.html'),
        controller: require('./dashboard.ctrl').default,
        controllerAs: 'vm',
        bindings: {
            terms:'<'
        }
    })
    .name
