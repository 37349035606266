import projectService from './service/project.service'
import projectControl from './project.ctrl'

export default angular.module('road.project', [])
    .service('projectService', projectService)
    .controller('projectCtrl', projectControl)
    .config(function ($stateProvider) {
        $stateProvider
            .state('project', {
                url: '/project/:id/:sectionId/:name',
                template: require('./project.html'),
                controller: projectControl,
                controllerAs: 'vm',
                title:'工程概况'
            })
    }).name
