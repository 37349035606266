/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-05-07 09:48:20
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-08-10 16:48:19
 * @FilePath: \roadcqc-mobile\src\pages\lq\realtime\service\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import realService from './realTime.service'
import paveService from './paveInfo.service'
import progresseService from './progress.service'

export default angular.module('service.lq.realTime', [])
    .service('lqRealTimeService', realService)
    .service('lqRealTimePaveService', paveService)
    .service('progressService', progresseService)
    .name