
export default class SumWarningStatistics {
    constructor($state,warningService, $scope, $rootScope, $interval) {
        this.service = warningService

        this.scope = $scope

        this.rootScope = $rootScope

        this.interval = $interval
        this.state = $state
        this.screen = false


        this.warningInfo = []
    }
    $doCheck(){
        if (this.terms == null) {
            return
        }
        if(this.terms.workAreaId == null || this.terms.startDate == null || this.terms.endDate == null || this.terms.dimension == null){
            return
        }
        if (this.engineerId !== this.terms.engineerId || this.workAreaId !== this.terms.workAreaId || this.layerId !== this.terms.layerId || this.startDate !== this.terms.startDate || this.endDate !== this.terms.endDate || this.dimension !== this.terms.dimension) {
            if(this.terms.materialType == 'lq'){
                if(this.rootScope.lqTimeMode == 'day'){
                    if(this.terms.startDate !== this.terms.endDate){
                        return
                    }
                }
            }else if(this.terms.materialType == 'sw'){
                if(this.rootScope.swTimeMode == 'day'){
                    if(this.terms.startDate !== this.terms.endDate){
                        return
                    }
                }
            }else if(this.terms.materialType == 'sn'){
                if(this.rootScope.snTimeMode == 'day'){
                    if(this.terms.startDate !== this.terms.endDate){
                        return
                    }
                }
            }
            this.engineerId = this.terms.engineerId ;
            this.workAreaId = this.terms.workAreaId ;
            this.layerId = this.terms.layerId ;
            this.startDate = this.terms.startDate ;
            this.endDate = this.terms.endDate ;
            this.dimension = this.terms.dimension;


            this.getData()
        }

    }
    getData(){
        let terms = this.terms
        let param = {
            'sectionId':terms.sectionId,
            'engineerId': terms.engineerId,
            'workAreaId': terms.workAreaId,
            'startDate': terms.startDate,
            'endDate': terms.endDate,
            'dimension':terms.dimension,
            'materialType':terms.materialType
        }
        if(param.workAreaId == null){
            return
        }
        if(terms.dimension == 1){
            param.layerId = terms.layerId
        }
        //预警
        this.service.warningInfo(param).then(res => {
            this.warningInfo = res;
        })
    }
    sumWarningDetail(){
        this.rootScope.terms_workAreaId = this.terms.workAreaId
        this.rootScope.terms_dimension = this.terms.dimension
        if(this.terms.materialType == 'sn'){
            this.rootScope.terms_layerId = this.terms.strength
        }else{
            this.rootScope.terms_layerId = this.terms.layerId
        }
        this.state.go('warningSumDetail',{terms:JSON.stringify(this.terms)})
    }
}
SumWarningStatistics.$inject = ['$state','warningService', '$scope', "$rootScope", "$interval"]