// /*
//  * @Author: zhouhm 1148305943@qq.com
//  * @Date: 2022-08-04 14:35:55
//  * @LastEditors: zhouhm 1148305943@qq.com
//  * @LastEditTime: 2022-08-18 20:32:17
//  * @FilePath: \roadcqc-mobile\src\pages\lq\realtime\progress.ctrl.js
//  * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
//  */
import '../../../assets/css/progressHorizontal.css'
import * as echarts from 'echarts';
import { pileNo2Str } from "_services/transformers";


export default class RealTimeProgressHorizontal {
    constructor($stateParams, progressService, $state, $interval, $rootScope) {
        this.state = $state
        this.service = progressService
        this.rootScope = $rootScope

        this.interval = $interval
        this.stateParams = $stateParams
        this.termsStr = this.stateParams.terms
        this.terms = JSON.parse(this.termsStr)


        // 并线/匝道数据
        this.Parallel = {}
        this.ParallelActive = false
        this.ParallelChartsWidth = ""
        this.ramp = {}
        this.rampActive = false
        this.bulletPos = {
            left: 0
        }


        // 横道图-施工进度
        this.allProgress = {}
        // 横道图
        this.startPileNo = ""
        this.endPileNo = ""

        this.speed = 0
        this.direction = "left"

        this.xPlanData = [{
                name: "right",
                construction: true,
                value: [0, 130, 200],
                itemStyle: {
                    normal: {
                        //柱子颜色
                        color: '#F8B62D',
                    }
                }
            },
            {
                name: "right",
                value: [2, 10, 20],
                construction: true,
                itemStyle: {
                    normal: {
                        //柱子颜色
                        color: '#595D80',
                    }
                }
            },
            {
                name: "left",
                value: [2, 60, 120],
                construction: true,
                itemStyle: {
                    normal: {
                        //柱子颜色
                        color: '#595D80',
                    }
                }
            },
        ]

        this.rampAndParallel = []
    }

    $onInit() {

    }

    // 工区变化
    $doCheck() {
        //工区变化时重新查询
        if (this.preWorkAreaId !== this.terms.workAreaId) {
            if (this.dayKMTimer) {
                this.interval.cancel(this.dayKMTimer)
            }
            this.startPileNo = this.terms.startPileNo
            this.endPileNo = this.terms.endPileNo
            if (this.terms.materialType == "lq") {
                this.lqProgressForApp()
                this.findLastLqPaverData()
            }
            if (this.terms.materialType == "sw") {
                this.swProgressForApp()
                this.SWfindLastLqPaverData()
            }

            // 定时速度
            if (this.SpeedTimer) {
                this.interval.cancel(this.SpeedTimer)
            }
            this.startSpeedTimer()

            this.countMileDetailList()
        }
        this.preWorkAreaId = this.terms.workAreaId
    }

    $onDestroy() {
        console.log('realTime   onDestry')
        this.interval.cancel(this.timer)
        this.interval.cancel(this.SpeedTimer)
        this.interval.cancel(this.dayKMTimer)
    }

    // 横道图自定义柱状样式
    renderItem(params, api) {
        let categoryIndex = api.value(0);
        let start = api.coord([api.value(1), categoryIndex]);
        let end = api.coord([api.value(2), categoryIndex]);
        let height = 10;

        let rectShape = echarts.graphic.clipRectByRect({
            x: start[0],
            y: start[1] - height / 2,
            width: end[0] - start[0],
            height: height,
        }, {
            x: params.coordSys.x,
            y: params.coordSys.y,
            width: params.coordSys.width,
            height: params.coordSys.height,
        });

        return rectShape && {
            type: 'rect',
            shape: rectShape,
            style: api.style(),
        };
    };

    // 横道图
    getMain(xPlanData, startPileNo, endPileNo, type, key) {
        xPlanData = xPlanData.map(item => {
            item.value = [item.value[0], item.value[1] - startPileNo, item.value[2] - startPileNo]
            return item
        })
        this.xPlanData1 = xPlanData.filter(item => {
            return item.name == 'left'
        })
        this.xPlanData2 = xPlanData.filter(item => {
            return item.name == 'right'
        })

        let tooltipData1 = this.xPlanData1.filter(item => {
            return item.construction == true
        })
        let tooltipData2 = this.xPlanData2.filter(item => {
            return item.construction == true
        })

        // console.log(xPlanData)

        let data = []
        data.push({
            type: 'custom',
            xAxisIndex: 0,
            yAxisIndex: 0,
            renderItem: this.renderItem,
            encode: {
                x: [1, 2],
                y: 0
            },
            data: this.xPlanData1,
        }, {
            type: 'custom',
            xAxisIndex: 1,
            yAxisIndex: 1,
            renderItem: this.renderItem,
            data: this.xPlanData2,
            encode: {
                x: [1, 2],
                y: 0
            },
        }, {
            type: 'pictorialBar',
            barGap: '-100%',
            symbolPosition: 'end',
            symbolSize: 50,
            symbolOffset: [0, 0],
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: tooltipData1.map((item, index) => {
                let globalItemOffsetValue = 0;
                if (item.value[0] == 1) {
                    globalItemOffsetValue = -147;
                } else if (item.value[0] == 2) {
                    globalItemOffsetValue = -294;
                }
                // console.log(item,index);
                let singleItemOffsetValue = globalItemOffsetValue + index * 147 + '%';
                let symbolOffset = [18, singleItemOffsetValue]
                item = {
                    pilNo: [item.value[1], item.value[2]],
                    pos: item.value[0],
                    value: item.construction ? item.value[2] : '',
                    symbol: item.status == 1 ? 'image://../../../assets/img/cqc-crossing/constructionGif.png' : 'image://../../../assets/img/cqc-crossing/unConstruction.png',
                    status: item.status,
                    symbolSize: [20,20],
                    construction: item.construction,
                    dayKM: item.dayKM,
                    symbolRepeatDirection: 'end',
                    symbolOffset: symbolOffset,
                    z: 100000,
                }
                return item
            })
        }, {
            type: 'pictorialBar',
            barGap: '-100%',
            symbolPosition: 'end',
            symbolSize: 50,
            symbolOffset: [0, 0],
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: tooltipData2.map((item, index) => {
                let globalItemOffsetValue = 0;
                if (item.value[0] == 1) {
                    globalItemOffsetValue = -147;
                } else if (item.value[0] == 2) {
                    globalItemOffsetValue = -294;
                }
                // console.log(item,index);
                let singleItemOffsetValue = globalItemOffsetValue + index * 147 + '%';
                let symbolOffset = [18, singleItemOffsetValue]
                item = {
                    pilNo: [item.value[1], item.value[2]],
                    pos: item.value[0],
                    value: item.construction ? item.value[2] : '',
                    symbol: item.status == 1 ? 'image://../../../assets/img/cqc-crossing/constructionGif.png' : 'image://../../../assets/img/cqc-crossing/unConstruction.png',
                    status: item.status,
                    symbolSize: [20,20],
                    construction: item.construction,
                    dayKM: item.dayKM,
                    symbolRepeatDirection: 'end',
                    symbolOffset: symbolOffset,
                    z: 100000,
                }
                return item
            })
        }, )

        let splitNumber

        let myChart
        if (type == 'thread') {
            let chartDom = document.getElementById('main');
            myChart = echarts.init(chartDom);
            splitNumber = (endPileNo - startPileNo) / 3
            splitNumber = Math.ceil(splitNumber)
        }
        if (type == 'Parallel') {
            let chartDom = document.getElementById(key);
            myChart = echarts.init(chartDom);
            splitNumber = endPileNo - startPileNo
            splitNumber = Math.ceil(splitNumber)
        }

        let option;
        option = {
            backgroundColor: "#fff",
            grid: [
                // 配置第一个图
                {
                    left: 40,
                    right: 5,
                    top: "0%",
                    height: 88,
                },
                // 配置第二个图
                {
                    left: 40,
                    right: 5,
                    height: 88,
                    bottom: 30
                },
            ],
            tooltip: {
                padding: 5,
                backgroundColor: "#FFF",
                borderColor:"#FFF",
                formatter: (params) => {
                    console.log(params)
                    let current = params.data.value;
                    // let construction = params.data.construction
                    if (Object.prototype.toString.call(current) === '[object Array]') {
                        if (this.dayKMTimer) {
                            this.interval.cancel(this.dayKMTimer)
                        }
                        // if (current && construction == false) {
                        if (current) {
                            let startStakeMark = pileNo2Str(current[1] + startPileNo);
                            let endStakeMark = pileNo2Str(current[2] + startPileNo);
                            return `<div style='color:#333333;font-weight:600;font-size:12px'>桩号:${startStakeMark}~${endStakeMark}</div>`;
                        } else {
                            return '';
                        }
                    } else {
                        let status = params.data.status
                        let pilNoArry = params.data.pilNo
                        let dayKM = params.data.dayKM
                        if (status == 1) {
                            let startStakeMark = pilNoArry[0] + startPileNo;
                            let endStakeMark = pilNoArry[1] + startPileNo;
                            let allMark = endStakeMark + 100
                            let startMark = startStakeMark
                            let width = (dayKM / (allMark - startMark) * 100).toFixed(2)
                            width > 100 ? width = 100 : ''

                            // 展示桩号
                            let showStartMark = pileNo2Str(startMark)
                            let showallMark = pileNo2Str(allMark)

                            // 定时任务
                            if (this.dayKMTimer) {
                                this.interval.cancel(this.dayKMTimer)
                            }
                            this.startDayKM(dayKM)

                            return `<div id='echartsChangeValue' style='color:#333333;font-weight:600;font-size:12px'>今日施工里程:${dayKM}m</div>
                                    <div style='width:120px;height:8px;border-radius:4px;background-color:rgba(128, 180, 131, 0.3);margin:auto;position: relative;'>
                                        <div style='width:${width}%;height:8px;border-radius:4px;background-color:#80B483;position: absolute;top:0;${this.direction}:0'></div>
                                    </div>
                                    <div style='display:flex;justify-content:space-between;align-items:center;color:#333333;font-weight:600;font-size:12px'>
                                        <div>${showStartMark}</div>
                                        <div>~</div>
                                        <div>${showallMark}</div>
                                    </div>
                                    `;
                        } else {
                            let startStakeMark = pilNoArry[0] + startPileNo;
                            let endStakeMark = pilNoArry[1] + startPileNo;
                            let showStartMark = pileNo2Str(startStakeMark)
                            let showallMark = pileNo2Str(endStakeMark)

                            return `<div style='color:#333333;font-weight:600;font-size:12px'>今日施工里程:${dayKM}m</div>
                                    <div style='display:flex;justify-content:space-between;align-items:center;color:#333333;font-weight:600;font-size:12px'>
                                        <div>${showStartMark}</div>
                                        <div>~</div>
                                        <div>${showallMark}</div>
                                    </div>
                                    `;
                        }
                    }
                },
                position: function(point, params, dom, rect, size) {
                    let pointX = 0;
                    let pointY = 0;
                    // #main
                    if (type == 'thread') {
                        const mainDom = document.getElementById('main');
                        console.log(mainDom.clientWidth)
                        if (mainDom.clientWidth > (dom.clientWidth + point[0] + 10)) {
                            pointX = point[0] + 10;
                        } else {
                            pointX = point[0] - dom.clientWidth - 10;
                        }
                        if (params.data.pos == 2) {
                            pointY = point[1] - 50
                        } else if (params.data.pos == 1) {
                            pointY = point[1] - 20
                        } else {
                            pointY = point[1] + 10
                        }
                    }
                    // if (type == 'Parallel') {
                    //     let chartDom = document.getElementById(key);
                    // }
                    console.log('point', point, params, dom, rect, size);
                    // 固定在顶部
                    console.log('dom', dom.clientWidth, dom.clientHeight);
                    return [pointX, pointY];
                }
            },
            xAxis: [{
                    type: 'value',
                    min: 0,
                    max: endPileNo - startPileNo,
                    interval: splitNumber,
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(89, 93, 128, 1)',
                        },
                    },
                    axisLabel: {
                        formatter: function(value, index) {
                            value = value + startPileNo
                            if (type == 'thread') {
                                if (index == 3) {
                                    return pileNo2Str(value) + '                '
                                } else {
                                    return pileNo2Str(value)
                                }
                            }
                            if (type == 'Parallel') {
                                if (index == 1) {
                                    return pileNo2Str(value) + '                '
                                } else {
                                    return pileNo2Str(value)
                                }
                            }
                        },
                    }
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    min: 0,
                    max: endPileNo - startPileNo,
                    interval: splitNumber,
                    position: 'top',
                    axisLabel: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(89, 93, 128, 1)',
                        },
                    },
                },
            ],
            yAxis: [{
                    type: 'category',
                    data: this.terms.materialType == "lq" ? ['下', '中', '上'] : this.terms.materialType == "sw" ? ['底', '下', '上'] : ['下', '中', '上'],
                    axisLabel: {
                        color: 'rgba(89, 93, 128, 1)',
                    },
                    axisTick: {
                        show: true,
                        length: 25,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(89, 93, 128, 1)',
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(89, 93, 128, 1)',
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(89, 93, 128, 1)',
                        },
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: 'rgba(238, 240, 249, 1)',
                        }
                    }
                },
                {
                    gridIndex: 1,
                    type: 'category',
                    data: this.terms.materialType == "lq" ? ['下', '中', '上'] : this.terms.materialType == "sw" ? ['底', '下', '上'] : ['下', '中', '上'],
                    axisLabel: {
                        color: 'rgba(89, 93, 128, 1)',
                    },
                    axisTick: {
                        show: true,
                        length: 25,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(89, 93, 128, 1)',
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(89, 93, 128, 1)',
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(89, 93, 128, 1)',
                        },
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: 'rgba(238, 240, 249, 1)',
                        }
                    }
                },
            ],
            dataZoom: [{
                    type: 'slider',
                    show: true,
                    realtime: true,
                    start: 0,
                    end: 100,
                    bottom: 16, //下边的距离
                    right:20,
                    height: 10,
                    xAxisIndex: [0, 1],
                    borderColor: 'transparent',
                    backgroundColor: '#e2e2e2',
                    // 拖拽手柄样式 svg 路径
                    handleIcon: 'M512 512m-208 0a6.5 6.5 0 1 0 416 0 6.5 6.5 0 1 0-416 0Z M512 192C335.264 192 192 335.264 192 512c0 176.736 143.264 320 320 320s320-143.264 320-320C832 335.264 688.736 192 512 192zM512 800c-159.072 0-288-128.928-288-288 0-159.072 128.928-288 288-288s288 128.928 288 288C800 671.072 671.072 800 512 800z',
                    handleColor: '#aab6c6',
                    handleSize: 20,
                    handleStyle: {
                        borderColor: '#aab6c6',
                        shadowBlur: 4,
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowColor: '#e5e5e5'
                    },
                    moveHandleStyle:{
                        opacity:0
                    }
                },
                {
                    type: 'slider',
                    realtime: true,
                    start: 0,
                    end: 100,
                    bottom: 16, //下边的距离
                    right:20,
                    height: 10,
                    xAxisIndex: [0, 1],
                    borderColor: 'transparent',
                    backgroundColor: '#e2e2e2',
                    // 拖拽手柄样式 svg 路径
                    handleIcon: 'M512 512m-208 0a6.5 6.5 0 1 0 416 0 6.5 6.5 0 1 0-416 0Z M512 192C335.264 192 192 335.264 192 512c0 176.736 143.264 320 320 320s320-143.264 320-320C832 335.264 688.736 192 512 192zM512 800c-159.072 0-288-128.928-288-288 0-159.072 128.928-288 288-288s288 128.928 288 288C800 671.072 671.072 800 512 800z',
                    handleColor: '#aab6c6',
                    handleSize: 20,
                    handleStyle: {
                        borderColor: '#aab6c6',
                        shadowBlur: 4,
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowColor: '#e5e5e5'
                    },
                    moveHandleStyle:{
                        opacity:0
                    }
                }
            ],
            series: [
                ...data
            ],
            legend: {
                show: false
            },
        };

        if (type == 'Parallel') {
            // let num=key.slice(4)
            // if(Number(num)%3==1){
            option.tooltip.position = [0, 20]
            // }
        }

        option && myChart.setOption(option);

        if (tooltipData1.length > 0 && tooltipData1[0].status == 1) {
            myChart.dispatchAction({
                type: 'showTip',
                seriesIndex: 2,
                dataIndex: 0
            })
        } else if (tooltipData2.length > 0 && tooltipData2[0].status == 1) {
            myChart.dispatchAction({
                type: 'showTip',
                seriesIndex: 3,
                dataIndex: 0
            })
        }
    }

    // 横道图-沥青施工进度
    lqProgressForApp() {
        let param = {
            engineerId: this.terms.engineerId,
            sectionId: this.terms.sectionId,
            workAreaId: this.terms.workAreaId
        }
        this.service.lqProgressForApp(param).then(data => {
            this.allProgress = data.filter(item => {
                return item.name == '合计进度'
            })[0]
        })
    }

    // 横道图-沥青获取最新的摊铺速度，桩号
    findLastLqPaverData() {
        let param = {
            'engineer.id': this.terms.engineerId,
            'workArea.id': this.terms.workAreaId
        }
        this.service.findLastLqPaverData(param).then(data => {
            this.speed = 0
            if (data.speed) {
                this.speed = data.speed
                this.direction = data.direction == '大桩号' ? 'left' : 'right'
                if (this.timer) {
                    this.interval.cancel(this.timer)
                }
                this.startTimer()
            }
        })
    }


    // 横道图-水稳获取最新的摊铺速度，桩号
    SWfindLastLqPaverData() {
        let param = {
            'engineer.id': this.terms.engineerId,
            'workArea.id': this.terms.workAreaId
        }
        this.service.SWfindLastLqPaverData(param).then(data => {
            this.speed = 0
            if (data.speed) {
                this.speed = data.speed
                this.direction = data.direction == '大桩号' ? 'left' : 'right'
                if (this.timer) {
                    this.interval.cancel(this.timer)
                }
                this.startTimer()
            }
        })
    }

    startTimer() {
        let that = this
        let allnum = 0
        this.timer = this.interval(() => {
            allnum = Number(that.allProgress.plug) + this.speed / 1000
            that.allProgress.plug = allnum.toFixed(5)
        }, 1000)
    }

    startDayKM(dayKM) {
        this.dayKMTimer = this.interval(() => {
            dayKM = Number(dayKM) + this.speed
            dayKM = dayKM.toFixed(2)
            document.getElementById('echartsChangeValue').innerHTML = `今日施工里程:${dayKM}m`
        }, 1000)
    }

    startSpeedTimer() {
        this.SpeedTimer = this.interval(() => {
            if (this.terms.materialType == "lq") {
                this.findLastLqPaverData()
            }
            if (this.terms.materialType == "sw") {
                this.SWfindLastLqPaverData()
            }
        }, 5000)
    }

    // 横道图-当日施工里程
    countMileDetailList() {
        this.rampAndParallel = []
        let param = {
            engineerId: this.terms.engineerId,
            workAreaId: this.terms.workAreaId,
            workType: this.rootScope.currentWorkArea ? this.rootScope.currentWorkArea.workAreaType : this.rootScope.currentWorkAreaClassify.workAreaType,
        }
        this.service.countMileDetailList(param).then(data => {
            this.xPlanData = []
            let dataMessage = data.data[0]
            if (data.data[1]) {
                this.rampAndParallel = data.data[1].map(item => {
                    item.under = true
                    // let index = item.layerName.indexOf('下') != -1 ? 0 : item.layerName.indexOf('中') != -1 ? 1 : 2
                    let index = item.layer == 1 || item.layer == 4 ? 2 : item.layer == 2 || item.layer == 5 ? 1 : 0
                    item.name = item.roadFloat
                    item.construction = true
                    item.value = [index, item.startPileNo, item.endPileNo]
                    item.dayKM = item.mile
                    item.itemStyle = {
                        normal: {
                            //柱子颜色
                            color: '#F8B62D',
                        }
                    }
                    return item
                })
            }
            if (dataMessage) {
                dataMessage = dataMessage.map(item => {
                    // let index = item.layerName.indexOf('下') != -1 ? 0 : item.layerName.indexOf('中') != -1 ? 1 : 2
                    let index = item.layer == 1 || item.layer == 4 ? 2 : item.layer == 2 || item.layer == 5 ? 1 : 0
                    item = {
                        name: item.roadFloat,
                        construction: true,
                        value: [index, item.startPileNo, item.endPileNo],
                        dayKM: item.mile,
                        itemStyle: {
                            normal: {
                                //柱子颜色
                                color: '#F8B62D',
                            }
                        },
                        status: item.status
                    }
                    return item
                })
                this.xPlanData = dataMessage
            } else {
                this.xPlanData = []
            }
            this.milestatisList()
        })
    }

    // 横道图-水稳施工进度
    swProgressForApp() {
        let param = {
            engineerId: this.terms.engineerId,
            sectionId: this.terms.sectionId,
            workAreaId: this.terms.workAreaId
        }
        this.service.swProgressForApp(param).then(data => {
            this.allProgress = data.filter(item => {
                return item.name == '合计进度'
            })[0]
        })
    }

    // 横道图-已施工
    milestatisList() {
        let param = {
            engineerId: this.terms.engineerId,
            workAreaId: this.terms.workAreaId,
            workType: this.rootScope.currentWorkArea ? this.rootScope.currentWorkArea.workAreaType : this.rootScope.currentWorkAreaClassify.workAreaType,
        }
        this.service.milestatisList(param).then(data => {
            if (data.data[1]) {
                let completeArr = data.data[1].map(item => {
                    item.complete = true
                    // let index = item.layerName.indexOf('下') != -1 ? 0 : item.layerName.indexOf('中') != -1 ? 1 : 2
                    let index = item.layer == 1 || item.layer == 4 ? 2 : item.layer == 2 || item.layer == 5 ? 1 : 0
                    item.name = item.roadFloat
                    item.construction = true
                    item.value = [index, item.startPileNo, item.endPileNo]
                    item.dayKM = item.mile
                    item.itemStyle = {
                        normal: {
                            //柱子颜色
                            color: '#595D80',
                        }
                    }
                    return item
                })
                this.rampAndParallel = this.rampAndParallel.concat(completeArr)
            }

            let dataMessage = data.data[0]
            if (dataMessage) {
                dataMessage = dataMessage.map(item => {
                    // let index = item.layerName.indexOf('下') != -1 ? 0 : item.layerName.indexOf('中') != -1 ? 1 : 2
                    let index = item.layer == 1 || item.layer == 4 ? 2 : item.layer == 2 || item.layer == 5 ? 1 : 0
                    item = {
                        name: item.roadFloat,
                        construction: false,
                        value: [index, item.startPileNo, item.endPileNo],
                        itemStyle: {
                            normal: {
                                //柱子颜色
                                color: '#595D80',
                            }
                        },
                    }
                    return item
                })
                this.xPlanData = this.xPlanData.concat(dataMessage)
            }
            // console.log(this.xPlanData)
            this.getMain(this.xPlanData, this.startPileNo, this.endPileNo, 'thread')
            this.roadTypeList()
        })
    }

    // 横道图-匝道并线
    roadTypeList() {
        let param = {
            engineerId: this.terms.engineerId,
            workAreaId: this.terms.workAreaId
        }
        this.service.roadTypeList(param).then(data => {
            let parallelList = data.data.parallel
            let rampList = data.data.ramp

            this.ramp = {
                all: 0,
                under: 0,
                list: []
            }
            if (rampList) {
                this.ramp = {
                    all: rampList.length,
                    under: 0,
                    list: rampList.map((item, index) => {
                        item = {
                            under: false, // 施工完成
                            complete: false, // 未施工
                            startPileNo: item.startPileno ? item.startPileno : 0,
                            endPileNo: item.endPileno ? item.endPileno : 0,
                            roadName: item.roadName,
                            showActive: false,
                            index: 'roadName' + index,
                            status: item.status,
                            status1: item.status1,
                            status2: item.status2,
                        }
                        item.lengthNumber = (item.endPileNo - item.startPileNo).toFixed(2)
                        item.showstartPileNo = pileNo2Str(item.startPileNo)
                        item.showendPileNo = pileNo2Str(item.endPileNo)
                        return item
                    })
                }
                this.ramp.list = this.ramp.list.map(item => {
                    this.rampAndParallel.forEach(el => {
                        if (el.startPileNo >= item.startPileNo && el.endPileNo <= item.endPileNo) {
                            if (el.under) {
                                item.under = true
                            } else if (el.complete) {
                                item.complete = true
                            }
                            return item
                        }
                        return item
                    })
                    return item
                })
                let underLength = this.ramp.list.filter(item => { return item.under == true }).length
                this.ramp.under = underLength
            }


            this.Parallel = {
                all: 0,
                under: 0,
                list: []
            }
            if (parallelList) {
                this.Parallel = {
                    all: parallelList.length,
                    under: 0,
                    list: parallelList.map((item, index) => {
                        item = {
                            under: false, // 施工完成
                            complete: false, // 未施工
                            startPileNo: item.startPileno ? item.startPileno : 0,
                            endPileNo: item.endPileno ? item.endPileno : 0,
                            basePileno: item.basePileno,
                            roadName: item.roadName,
                            index: index + 1,
                            key: 'main' + index
                        }
                        // let clientHeight=document.documentElement.clientHeight - 30
                        // let ChartsWidth
                        // if ((this.endPileNo-this.startPileNo)!=0){
                        //     ChartsWidth=((item.endPileNo-item.startPileNo)/(this.endPileNo-this.startPileNo)).toFixed(2)
                        // }
                        // item.ParallelChartsWidth=Number((ChartsWidth*clientHeight).toFixed(1))+50+'px'
                        return item
                    })
                }

                // this.rampAndParallel = this.rampAndParallel.map((item, index) => {
                //     // 假数据
                //     if (index == 1) {
                //         item.startPileNo = 140500 + item.startPileNo
                //         item.endPileNo = 141000 + item.endPileNo
                //         item.value[1] = item.startPileNo
                //         item.value[2] = item.endPileNo
                //     }
                //     return item
                // })

                this.Parallel.list = this.Parallel.list.map(item => {
                    item.list = []
                    this.rampAndParallel.forEach(el => {
                        if (el.startPileNo >= item.startPileNo && el.endPileNo <= item.endPileNo) {
                            if (el.under) {
                                item.under = true
                            } else if (el.complete) {
                                item.complete = true
                            }
                            item.list.push(el)
                            return item
                        }
                        return item
                    })
                    return item
                })
                let underLength = this.Parallel.list.filter(item => { return item.under == true }).length
                this.Parallel.under = underLength
                if (this.Parallel.list.length >= 3) {
                    this.ParallelChartsWidth = (document.documentElement.clientHeight - 30) / 3 + 'px'
                } else {
                    this.ParallelChartsWidth = (document.documentElement.clientHeight - 30) + 'px'
                }

                setTimeout(() => {
                    this.Parallel.list.forEach(item => {
                        this.getMain(item.list, item.startPileNo, item.endPileNo, 'Parallel', item.key)
                    })
                }, 1000);
            }
        })
    }

    checkDetails(item) {
        if (!item.showActive) {
            this.ramp.list = this.ramp.list.map(item => {
                item.showActive = false
                return item
            })
            let div = document.querySelector('.' + item.index);
            let clientHeight = document.documentElement.clientHeight;
            if (clientHeight - div.getBoundingClientRect().top < 280 && div.getBoundingClientRect().bottom < 280) {
                this.bulletPos = {
                    left: '-60px'
                }
            } else if (clientHeight - div.getBoundingClientRect().top >= 280) {
                this.bulletPos = {
                    left: 0
                }
            } else {
                this.bulletPos = {
                    right: 0
                }
            }
            item.showActive = !item.showActive
        } else {
            item.showActive = !item.showActive
        }
    }
}

RealTimeProgressHorizontal.$inject = ['$stateParams', 'progressService', '$state', '$interval', '$rootScope']