import {addDate, dateFormat} from "_services/transformers";

export default class MainCtrl {
    constructor($scope,http, $state, systemService,$rootScope,$window) {
        this.http = http
        //日期组件声明的值
        this.scope = $scope
        $scope.picker={
            opened:false,
            startDateOpened:false,
            endDateOpened:false
        }
        $scope.dateOptions = {
            showWeeks: false,
            dateDisabled: this.isDisabled,
            customClass: this.getDayClass
        }


        this.moduleTypeChangeShow = true
        this.state = $state
        this.window = $window
        this.service = systemService
        this._moduleType = 'mixplant'
        this.isProjectChange = false
        this.rootScope = $rootScope
        this.rootScope.where = 'cur'
        this.rootScope.service = systemService

        this.isCollapsed = true


        this.navShow = true
        this.rootScope.navShow = true
        this.linkChoose = null
        this.currentMixplant = null
        this.mixplantList = []

        this.rootScope.dateCurrentNum = 0
        this.rootScope.dateCurrent = []
        this.rootScope.dateArr = []

        this.rootScope.isDp = false
    }

    $onInit() {
        let thatModuleType = this.window.localStorage.getItem('moduleType')
        console.log('测试:'+thatModuleType);
        let state = this.state.current.name
        let now = new Date()
        this.rootScope.startDate = dateFormat(addDate(now, -7), 'yyyy-MM-dd')
        this.rootScope.startDateCurrent = new Date(this.rootScope.startDate)
        this.rootScope.endDate = dateFormat(now, 'yyyy-MM-dd')
        this.rootScope.endDateCurrent = new Date(this.rootScope.endDate)
        this.rootScope.datePart = dateFormat(new Date(), 'yyyy-MM-dd')
        this.rootScope.datePartCurrent = new Date()

        if(state.indexOf('history') > 0){
            this.where = 'his'
        }else if(state.indexOf('current') > 0){
            this.where = 'cur'
        }else if(state.indexOf('summary') > 0){
            this.where = 'sum'
        }else{
            this.where = 'rel'
        }

        if(this.rootScope.currentProjectClassify == null){
            this.fetchProject()
        }

        if (thatModuleType){
            this._moduleType = thatModuleType
            this.window.localStorage.removeItem('moduleType');
        }

        if(state.indexOf('transport') > 0){
            this._moduleType = 'transport'
        }else if(state.indexOf('mixplant') > 0){
            this._moduleType = 'mixplant'
        }else if(state.indexOf('pave') > 0){
            this._moduleType = 'pave'
        }else{
            this._moduleType = 'roll'
        }

        this.rootScope.dateCurrentNum = 0 ;
        this.rootScope.materialType = this.materialType;
    }


    fetchProject(){
        this.service.fetchProject(this.materialType).then(res => {
            this.rootScope.projectListClassify = res;
            if (Array.isArray(res) && res.length > 0) {
                if(this.rootScope.currentProject){
                    let isExist = res.filter(item =>{
                        return item.id ==  this.rootScope.currentProject.id
                    })
                    if(isExist.length == 1){
                        this.rootScope.currentProjectClassify =  isExist[0]
                        this.fetchWorkArea()
                    }
                }else{
                    this.rootScope.currentProjectClassify =  res[0]
                    this.fetchWorkArea()
                }
            }else{
                this.rootScope.currentProjectClassify = null
            }
        })
    }

    fetchWorkArea() {
        if (this.rootScope.currentProjectClassify == null) {
            return
        }
        if(this.isProjectChange){
            this.rootScope.currentWorkAreaClassify = null
        }
        return this.service.fetchWorkArea(this.rootScope.currentProjectClassify.id, this.materialType).then(res => {
            this.rootScope.workAreaListClassify = res || []
            this.rootScope.currentWorkAreaClassify = null
            if (Array.isArray(res) && res.length > 0) {
                this.rootScope.currentWorkAreaClassify = res[0]
                this.currentSection = res[0] && res[0].section
                this.fetchWorkAreaDp()
                /*转换选择环节展示数据*/
                let arr = [];
                if(this.rootScope.currentWorkAreaClassify.chainList){
                    for(let i=0;i<this.rootScope.currentWorkAreaClassify.chainList.length;i++){
                        let obj={};
                        if(this.materialType == 'sn'){
                            if(this.rootScope.currentWorkAreaClassify.chainList[i]=="mixplant"){
                                obj.name="拌和";
                                obj.value=this.rootScope.currentWorkAreaClassify.chainList[i]
                                arr.push(obj);
                            }
                        }else{
                            if(this.rootScope.currentWorkAreaClassify.chainList[i]=="mixplant"){
                                obj.name="拌和";
                                obj.value=this.rootScope.currentWorkAreaClassify.chainList[i]
                            }else if(this.rootScope.currentWorkAreaClassify.chainList[i]=="transport"){
                                obj.name="运输";
                                obj.value=this.rootScope.currentWorkAreaClassify.chainList[i]
                            }else if(this.rootScope.currentWorkAreaClassify.chainList[i]=="pave"){
                                obj.name="摊铺";
                                obj.value=this.rootScope.currentWorkAreaClassify.chainList[i]
                            }else {
                                obj.name="碾压";
                                obj.value='roll'
                            }
                            arr.push(obj)
                        }
                    }
                    this.rootScope.currentWorkAreaClassify.chainList  = arr;
                    this.moduleTypeChangeShow = true
                    this._moduleType = this.rootScope.currentWorkAreaClassify.chainList[0].value
                    this.moduleType = this.rootScope.currentWorkAreaClassify.chainList[0].value
                }               
            }else{
                this.rootScope.currentWorkAreaClassify = null
            }
        })
    }

    fetchWorkAreaDp() {
        return this.service.fetchWorkAreaDp(this.rootScope.currentWorkAreaClassify.id).then(res => {
            window.sessionStorage.setItem("isDp",res)
            this.rootScope.isDp = res
         })
    }

    navShowClick(){
        this.navShow = !this.navShow
        this.rootScope.navShow = this.navShow
    }
    get navShow(){
        return this.rootScope.navShow
    }
    set navShow(d){
        this.rootScope.navShow = d
    }
    unFoldClick(item){
        this.moduleType = item
    }
    linkChooseClick(){
        this.linkChoose = !this.linkChoose

    }

    //禁用日期赋值
    isDisabled(obj) {
        let date = obj.date,
            mode = obj.mode;
    }


    //可点击的日期高亮显示
    getDayClass(data) {
        let date = data.date,
            mode = data.mode;
        let defaultDate = dateFormat(date,'yyyy-MM-dd')
        if(mode == 'day'){
            if(this.$root.dateCurrentNum == 0){
                let arr = defaultDate.split('-');
                let str = '';
                if(parseInt(arr[1])<9){
                    if(arr[2] == "01"){
                        str = arr[0]+"-0"+parseInt(arr[1]).toString()
                    }else{
                        str = arr[0]+"-0"+(parseInt(arr[1])+1).toString()
                    }
                }else if(parseInt(arr[1]) == 12){
                    if(arr[2] == "01"){
                        str = arr[0]+"-"+parseInt(arr[1]).toString()
                    }else{
                        str = (parseInt(arr[0])+1).toString()+"-"+"01"
                    }
                }else{
                    if(arr[2] == "01"){
                        if(parseInt(arr[1]) == 9){
                            str = arr[0]+"-0"+parseInt(arr[1]).toString()
                        }else{
                            str = arr[0]+"-"+parseInt(arr[1]).toString()
                        }
                    }else {
                        str = arr[0] + "-" + (parseInt(arr[1]) + 1).toString()
                    }
                }
                str = str.replace(/\-/g,"")
                //计算前一月和后一月
                var monthStr = str.substring(str.length-2);
                var yearStr = str.substring(0,4)
                var str0 = ''   //前一月
                var str1 = ''   //后一月
                if(parseInt(monthStr)==1){
                    str0 = (parseInt(yearStr) - 1).toString() + "12"
                }else{
                    if(parseInt(monthStr) - 1 < 10 ){
                        str0 = yearStr +'0'+(parseInt(monthStr) - 1).toString()
                    }else{
                        str0 = yearStr +(parseInt(monthStr) - 1).toString()
                    }

                }
                if(parseInt(monthStr)==12){
                    str1 = (parseInt(yearStr) + 1).toString() + "01"
                }else{
                    if(parseInt(monthStr) + 1 < 10 ){
                        str1 = yearStr +'0'+(parseInt(monthStr) + 1).toString()
                    }else{
                        str1 = yearStr +(parseInt(monthStr) + 1).toString()
                    }

                }



                var paramObj = {};
                paramObj.materialType = this.$root.materialType;
                paramObj.workAreaId = this.$root.currentWorkAreaClassify.id;
                paramObj.yearMonthStr = str;
                this.$root.dateCurrentNum +=1
                if(this.$root.dateArr.indexOf(str) == -1){
                    this.$root.dateArr.push(str)
                    this.$root.service.fetchWorkCalendar(paramObj).then(res => {
                        if(res.length > 0){
                            this.$root.dateCurrent = this.$root.dateCurrent.concat(res)
                            this.$root.dateCurrent = Array.from(new Set(this.$root.dateCurrent))
                        }
                        for(let i=0;i<this.$root.dateCurrent.length;i++){
                            if(mode !== 'day' || this.$root.dateCurrent[i]=== defaultDate){
                                return 'full'
                            }
                        }
                        return '';
                    })
                }
                var paramObj0 = {};
                paramObj0.materialType = this.$root.materialType;
                paramObj0.workAreaId = this.$root.currentWorkAreaClassify.id;
                paramObj0.yearMonthStr = str0;
                if(this.$root.dateArr.indexOf(str0) == -1){
                    this.$root.dateArr.push(str0)
                    this.$root.service.fetchWorkCalendar(paramObj0).then(res => {
                        if(res.length > 0){
                            this.$root.dateCurrent = this.$root.dateCurrent.concat(res)
                            this.$root.dateCurrent = Array.from(new Set(this.$root.dateCurrent))
                        }
                    })
                }
                var paramObj1 = {};
                paramObj1.materialType = this.$root.materialType;
                paramObj1.workAreaId = this.$root.currentWorkAreaClassify.id;
                paramObj1.yearMonthStr = str1;
                if(this.$root.dateArr.indexOf(str1) == -1){
                    this.$root.dateArr.push(str1)
                    this.$root.service.fetchWorkCalendar(paramObj1).then(res => {
                        if(res.length > 0){
                            this.$root.dateCurrent = this.$root.dateCurrent.concat(res)
                            this.$root.dateCurrent = Array.from(new Set(this.$root.dateCurrent))
                        }
                    })
                }
            }else if(this.$root.dateCurrentNum == 42){
                this.$root.dateCurrentNum = 0
                for(let i=0;i<this.$root.dateCurrent.length;i++){
                    if(mode !== 'day' || this.$root.dateCurrent[i]=== defaultDate){
                        return 'full'
                    }
                }
                return '';
            }else{
                this.$root.dateCurrentNum +=1
                for(let i=0;i<this.$root.dateCurrent.length;i++){
                    if(mode !== 'day' || this.$root.dateCurrent[i]=== defaultDate){
                        return 'full'
                    }
                }
                return '';
            }

        }
    }


    open(){
        this.scope.picker.opened = !this.scope.picker.opened

    }
    startDateOpen(){
        this.scope.picker.startDateOpened = !this.scope.picker.startDateOpened

    }
    endDateOpen(){
        this.scope.picker.endDateOpened = !this.scope.picker.endDateOpened

    }


    get projectList (){
        return  this.rootScope.projectListClassify
    }


    set projectList(l){
        this.rootScope.projectListClassify = l

    }

    get currentMixplant (){
        return this.rootScope.currentMixplant
    }

    set currentMixplant(l){
        this.rootScope.currentMixplant = l
        this.rootScope.$broadcast("currentMixplantSet");
    }

    get where (){
        return this.rootScope.where
    }

    set where(l){
        this.rootScope.where = l
    }


    get workAreaList(){
        let workAreaList =  this.rootScope.workAreaListClassify
        if(!Array.isArray(workAreaList)){
            return workAreaList
        }
        let result = []
        workAreaList.forEach(w=>{
            if(w.workAreaType == this.materialType){
                result.push(w)
            }
        })
        return result ;
    }

    set workAreaList(l){
        this.rootScope.dateCurrent = [];
        this.rootScope.dateArr = []
        this.rootScope.dateCurrentNum = 0;

        this.rootScope.workAreaListClassify = l
    }

    set currentProject(cur) {
        this.moduleTypeChangeShow = true
        if(cur != this.rootScope.currentProjectClassify){
            this.isProjectChange = true
            // if (window.sessionStorage.getItem("isDp")) {
            //     window.sessionStorage.removeItem("isDp")
            // }
            this.rootScope.isDp=false
            this.rootScope.dateCurrent = []
            this.rootScope.dateArr = []
            //工程切换
            this.rootScope.currentProjectClassify = cur
            //级联工区
            this.fetchWorkArea()
        }
    }

    get currentProject() {
        return this.rootScope.currentProjectClassify
    }

    set currentWorkArea(w){
        let arr = []
        if(w.chainList){
            if(!w.chainList[0].value){
                for(let i=0;i<w.chainList.length;i++){
                    let obj={};
                    if(this.materialType == 'sn'){
                        if(w.chainList[i]=="mixplant"){
                            obj.name="拌和";
                            obj.value=w.chainList[i]
                            arr.push(obj)
                        }
                    }else{
                        if(w.chainList[i]=="mixplant"){
                            obj.name="拌和";
                            obj.value=w.chainList[i]
                        }else if(w.chainList[i]=="transport"){
                            obj.name="运输";
                            obj.value=w.chainList[i]
                        }else if(w.chainList[i]=="pave"){
                            obj.name="摊铺";
                            obj.value=w.chainList[i]
                        }else {
                            obj.name="碾压";
                            obj.value='roll'
                        }
                        arr.push(obj)
                    }
                }
                w.chainList  = arr;
                this.moduleTypeChangeShow = true
                this._moduleType = w.chainList[0].value
                this.moduleType = w.chainList[0].value
            }
        }
        this.rootScope.currentWorkAreaClassify = w
        // if (window.sessionStorage.getItem("isDp")) {
        //     window.sessionStorage.removeItem("isDp")
        // }
        this.fetchWorkAreaDp()
        this.moduleTypeChangeShow = true;
        this.currentSection = w && w.section
    }

    get currentWorkArea() {
        return  this.rootScope.currentWorkAreaClassify
    }

    get isDp() {
        return this.rootScope.isDp
    }


    get moduleType(){
        return this._moduleType
    }

    set moduleType(moduleType){
        this._moduleType = moduleType
        if(!this.moduleTypeChangeShow){
            this.rootScope.moduleTypeChange = true
        }else{
            this.moduleTypeChangeShow = false
            this.rootScope.moduleTypeChange = false
        }
        if(this.rootScope.where === 'his'){
            this.state.go(this.materialType+'.' + moduleType + '.history')
        } else if (this.rootScope.where === 'cur') {
            this.state.go(this.materialType+'.' + moduleType + '.current')
        } else if (this.rootScope.where === 'sum') {
            this.state.go(this.materialType+'.' + moduleType + '.summary')
        }else {
            this.state.go(this.materialType+'.' + moduleType + '.realTime')
        }

    }

    get datePartCurrent() {
        return this.rootScope.datePartCurrent
    }

    set datePartCurrent(d) {
        this.rootScope.datePartCurrent = d
        this.rootScope.datePart = dateFormat(d,"yyyy-MM-dd")
        this.rootScope.$broadcast("datePartChange");
    }

    get startDateCurrent() {
        return this.rootScope.startDateCurrent
    }

    set startDateCurrent(d) {
        this.rootScope.startDateCurrent = d
        this.rootScope.startDate = dateFormat(d,"yyyy-MM-dd")
        this.rootScope.$broadcast("startDateChange")
    }

    get endDateCurrent() {
        return this.rootScope.endDateCurrent
    }

    set endDateCurrent(d) {
        this.rootScope.endDateCurrent = d
        this.rootScope.endDate = dateFormat(d,"yyyy-MM-dd")
        this.rootScope.$broadcast("endDateChange")
    }



    goStateHis() {
        this.rootScope.where = 'his'
        let moduleType = this._moduleType
        this.state.go(this.materialType+'.' + moduleType + '.history')
    }

    goStateCur() {
        this.isCollapsed = false
        this.rootScope.where = 'cur'
        let moduleType = this._moduleType
        this.state.go(this.materialType+'.' + moduleType + '.current')
    }
    goStateRealTime() {
        this.isCollapsed = true
        this.rootScope.where = 'rel'
        this.state.go(this.materialType+'.realTime')
    }
    goStateSum(){
        this.isCollapsed = true
        this.rootScope.where = 'sum'
        this.state.go(this.materialType+'.summary')
    }

}
MainCtrl.$inject = ['$scope','http', '$state', 'systemService','$rootScope','$window']
