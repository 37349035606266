export default class SwRealTimeMixplantCtrl{
    constructor(swRealTimeService,$rootScope,$interval){
        this.service = swRealTimeService
        this.rootScope = $rootScope
        this.outPutList = []
        this.interval = $interval
    }
    $doCheck(){
        //当且仅当换拌和楼是查询
        if(this.terms == null){
            return
        }
        if(this.preMixplantId != this.terms.mixplantId){
            this.fetchAnalyzeData()
        }
        this.preMixplantId = this.terms.mixplantId
    }

    $onInit(){
        this.startTimer()
    }

    $onDestroy(){
        console.log('mixplant   onDestry')
        this.interval.cancel(this.timer)
    }



    startTimer(){
        let that = this
        this.timer =  this.interval(function(){
            that.fetchAnalyzeData()
        },30*1000)
    }


    //取30分钟内 最新的一条数据
    fetchAnalyzeData(){
        let terms = this.terms
        if(terms.mixtureId == null){
            return
        }
        let param = {
            'sectionId':terms.sectionId,
            'workAreaId':terms.workAreaId,
            'mixplantId':terms.mixplantId,
            'mixtureId':terms.mixtureId,
            'startDateTime':terms.startDateTime,
            'endDateTime':terms.endDateTime
        }

        this.service.fetchRealTimeOutput(param).then(res=>{
            this.outPutList = res.second
            this.rootScope.dischargeDate = res.first

        })
    }
}

SwRealTimeMixplantCtrl.$inject = ['swRealTimeService','$rootScope',"$interval"]