import BaseCtrl from "_pages/common/baseCtrl";
import { dateFormat } from "_services/transformers";
// import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
// import PhotoSwipe from "photoswipe";
// import 'photoswipe/dist/photoswipe.css'
// import 'photoswipe/dist/default-skin/default-skin.css'


export default class LqRollRealTimeCloudPictureCtrl extends BaseCtrl {
    constructor($scope, $rootScope, $interval, systemService) {
        super();
        this.scope = $scope;
        this.rootScope = $rootScope;
        this.interval = $interval;
        this.systemService = systemService;
        this.requestError = false

        console.log(this.rootScope)
        this.rollCloudPicture = {
            // 指标信息  默认为碾压遍数
            standard: 'times',
            // 工程
            engineerId: this.rootScope.cloudpicture.engineerId,
            // 标段
            sectionId: this.rootScope.cloudpicture.sectionId,
            // 工区
            workAreaId: this.rootScope.cloudpicture.workAreaId,
            // 当前工区下的层位列表
            layerList: this.rootScope.cloudpicture.layerList,
            // 当前被选中层位
            currentRollLayer: this.rootScope.cloudpicture.currentRollLayer || { id: 3 },
            // 当前工区下的压路机类型列表
            rollerTypeList: this.rootScope.cloudpicture.rollerTypeList,
            // 当前被选中的压路机类型
            currentRollerType: this.rootScope.cloudpicture.curRollerType || { value: 2 },
            // 彩图
            colorGraph: '',
            // 图例
            legend: ''

        };
        this.materialType = this.rootScope.materialType
        this.currentLayerId = this.rollCloudPicture.currentRollLayer ? this.rollCloudPicture.currentRollLayer.id : ''
        // 是否使用新设备(工区列表下可查)
        this.mtype = this.rootScope.currentWorkAreaClassify.mtype
        this.url = `http://218.2.99.139:15710/?workArea=${this.rollCloudPicture.workAreaId}&engineerId=${this.rollCloudPicture.engineerId}&layer=${this.currentLayerId}&isHistory=realTime&materialType=${this.materialType}&machineType=13&time=2021-09-05&getTime=${new Date().getTime()}`
    }

    $onInit() {
        setTimeout(() => {
            this.searchCloudPicture()
            if (document.getElementById('iframe')) {
                document.getElementById('iframe').src = this.url
            }
        }, 1000)
    }
    /**
     * 彩图缩放工具
     */
    openPhotoSwipe() {
        var pswpElement = document.querySelectorAll('.pswp')[0];
        // build items array
        var items = [{
            src: this.pave_cloud_picture_response.colorGraphMax,
            w: this.pave_cloud_picture_response.colorGraphWidthMax,
            h: this.pave_cloud_picture_response.colorGraphHeightMax
        }];
        // define options (if needed)
        var options = {
            index: 0
        };

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    }
    /**
     * 获取碾压彩图和图例
     */
    searchCloudPicture() {
        if (this.searchCloudPictureParamWrong(this.rollCloudPicture)) {
            this.requestError = true
            return
        }
        // 组装请求参数
        let param = {
            // 工程
            engineerId: this.rollCloudPicture.engineerId,
            // 标段
            sectionId: this.rollCloudPicture.sectionId,
            // 工区
            workAreaId: this.rollCloudPicture.workAreaId,
            // 层位id
            layerId: this.rollCloudPicture.currentRollLayer.id,
            //
            category: this.rollCloudPicture.standard,
            // 压路机类型
            rollerType: this.rollCloudPicture.currentRollerType.value,
            // 时间
            datePart: dateFormat(new Date(), 'yyyy-MM-dd'),
            timeStart: '00:00',
            timeEnd: '23:59'
        };

        this.systemService.fetchRollCloudPicture(param, this.materialType).then(res => {
            console.log(res)
            if (res == null) {
                this.requestError = true
                return;
            }
            this.pave_cloud_picture_response = res
            this.rollCloudPicture.colorGraph = res.colorGraphMax;
            this.rollCloudPicture.legend = res.legend;
        }).catch(res => {
            this.requestError = true
        });
    }

    /**
     * 参数检查
     * @param terms
     * @returns {boolean}
     */
    searchCloudPictureParamWrong(param) {
        return param == null || param.engineerId == null || param.sectionId == null || param.workAreaId == null
        // || param.currentRollLayer == null || param.standard == null || param.currentRollerType == null
    }

    /**
     * get和set方法
     */
    get standard() {
        return this.rollCloudPicture.standard;
    }

    set standard(standardValue) {
        if (standardValue != this.rollCloudPicture.standard) {
            this.rollCloudPicture.standard = standardValue;
            this.searchCloudPicture();
        }
    }

    get currentLayer() {
        return this.rollCloudPicture.currentRollLayer;
    }

    set currentLayer(curLayer) {
        if (curLayer != null && curLayer.id != this.rollCloudPicture.currentRollLayer.id) {
            this.rollCloudPicture.currentRollLayer = curLayer;
            this.searchCloudPicture();
        }
    }

    get currentRollerType() {
        return this.rollCloudPicture.currentRollerType;
    }

    set currentRollerType(curRollerType) {
        if (curRollerType != null && curRollerType.value != this.rollCloudPicture.currentRollerType.value) {
            this.rollCloudPicture.currentRollerType = curRollerType;
            this.searchCloudPicture();
        }
    }
    /**
     * 返回上级
     */
    goBack() {
        console.log('in')
        history.back();
    }
}

LqRollRealTimeCloudPictureCtrl.$inject = ['$scope', "$rootScope", "$interval", "systemService"]