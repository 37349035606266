import {pileNo2Str} from "_services/transformers";

export default class SwRealTimePaveService {
    constructor(http) {
        this.http = http

        this.height = 88;

        this.width = 80;

        this.pos = [{top: 6, left: 4}, {top: 18, left: 32}, {top: 40, left: 8}, {top: 80, left: 21}, {
            left: 26,
            top: 60
        }]

        this.pos_g = [{top: 60, left: 20}, {top: 50, left: 80}, {top: 25, left: 60}, {top: 70, left: 60}, {
            top: 80,
            left: 80
        }]


    }

    _getMappingIcon(item){
        if(item.typeName.indexOf('胶') >=0){
            return 'j_' + (item.online ? 'run' : 'stop')
        }else if(item.typeName.indexOf('单') >=0){
            return 'single_' + (item.online ? 'run' : 'stop')
        }else if(item.typeName.indexOf('钢') >=0){
            return  'g_' + (item.online ? 'run' : 'stop');
        }
    }

    /**
     *   处理压路机的显示位置及图标
     * @param info
     * @private
     */
    _handleRollShow(info) {
        if (!Array.isArray(info.rollers)) {
            return
        }
        let index = 0;
        let index_g = 0;
        info.rollers.forEach(item => {
            item.icon = this._getMappingIcon(item)
            if (item.typeName.indexOf('胶') >= 0) {
                item.left = this.pos[index].left;
                item.top = this.pos[index].top
                index++
            } else if (item.typeName.indexOf('钢') >= 0) {
                item.left = this.pos_g[index_g].left
                item.top = this.pos_g[index_g].top
                index_g++;
            }
            if (index >= this.pos.length) {
                let random = {
                    top: this.height * Math.random(),
                    left: this.width * Math.random()
                }
                this.pos.push(random)
            }
            if (index_g >= this.pos_g.length) {
                let random = {
                    top: this.height * Math.random(),
                    left: this.width * Math.random()
                }
                this.pos_g.push(random)
            }

            item.style = `left:${item.left}%;top:${item.top}%`
            item.pileNoStr = pileNo2Str(item.pileNo)
        })
    }




    _handlePaveShow(info) {
        //处理摊铺机显示
        if (!Array.isArray(info.pavers) || info.pavers.length === 0) {
            return
        }
        let top = 0 ;
        let height = 100 / info.pavers.length ;
        info.pavers.forEach(paver => {
            paver.icon = paver.online ? 'run' : 'stop'
            paver.style= `top:${top}%;height:${height}%`
            top+=height;
        })
        //只有一台摊铺机
        if (info.pavers.length === 1) {
            info.pavers[0].icon = info.pavers[0].icon + '_one'
        }


        info.pavers.forEach(paver => {
            //取摊铺的桩号
            if (paver.pileNo != null) {
                info.currentPileNoStr = pileNo2Str(paver.pileNo)
            }
        })



    }

    _checkIsOnWork(info) {
        if (Array.isArray(info.rollers) && info.rollers.length > 0) {
            info.rollers.forEach(r => {
                if (r.online) {
                    info.hasWork = true
                }
            })
        }
        if (Array.isArray(info.pavers) && info.pavers.length > 0) {
            info.pavers.forEach(r => {
                if (r.online) {
                    info.hasWork = true
                }
            })
        }

        if (!info.hasWork) {
            info.currentPileNoStr = '已停工'
        }
    }

    fetchOnlineMachineInfo(terms) {
        return this.http.get("/sw/complex/frontWork", terms).then(res => {
            let info = res.data
            this._handleRollShow(info);

            this._handlePaveShow(info)

            this._checkIsOnWork(info)

            if (Array.isArray(info.rollerAvgInfo) && info.rollerAvgInfo.length > 0) {
                info.col = 12 / (info.rollerAvgInfo.length + 1)
            }

            return info
        })
    }

        
        /**
     * 根据工区判断是否为低配工区
     */
    fetchWorkAreaDp(workareaId) {
        if (workareaId == null) {
            return 
        }
        return this.http
            .get('/base/workarea/checkForTime', {workareaId: workareaId})
            .then(res => res.data)
    }

    fetchWorkAreaDpInfo(terms) {
        return this.http.get("/data/fortime/realtime/sw", terms).then(res => {
            let info = res.data
            return info
        })
    }

}
SwRealTimePaveService.$inject = ['http']
