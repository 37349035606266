/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-08-05 14:01:54
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-09-01 17:07:42
 * @FilePath: \roadcqc-mobile\src\services\http\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import HomeCtrl from "_pages/home.ctrl";

/**
 *  网络请求服务封装
 */

class Http {
    constructor($http, $rootScope) {
        this.$http = $http;
        this.global = $rootScope

        this.$http.defaults.headers.post["Content-Type"] = "text/plain";

        this.options = {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-store'
            }
        }
        this.postOptions = {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Cache-Control': 'no-store'
            }
        }
        this.basePath = '/api';
    }

    get(url, param, cb) {

        if (typeof param === 'function') {
            cb = param
        }
        if (cb == undefined) {
            cb = (res) => {

                // console.log(res)
                return res
            }
        }
        let gets = {method: 'GET', params: param, url: this.basePath + url}
        Object.assign(gets, this.options)
        return this.$http(gets).then((res) => cb(res))
    }


    post(url, param, cb) {

        if (cb == undefined) {
            cb = (res) => {
                // console.log(res)
                return res
            }
        }
        let posts = {method: 'POST', data: param, url: this.basePath + url}
        Object.assign(posts, this.postOptions)

        return this.$http(posts).then((res) => cb(res))
    }
}
Http.$inject =['$http',"$rootScope"]


export default angular.module('http', [])
    .service('http', Http)
    .name;
