import Warning from './warning'

import datePicker from './datePicker'

import currentSearch from './currentSearch'

/**
 *  所有 组件components 指令directive
 */

export default angular.module('road.component', [
    Warning,
    datePicker,
    currentSearch
]).name;
