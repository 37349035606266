/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-08-05 14:01:54
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-09-01 14:19:21
 * @FilePath: \roadcqc-mobile\src\components\warning\history\history_warning_list.ctrl.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import checkParamReady from '../warning_fn'
export default  class HistoryWarningList {
    constructor( warningService,$window){
        this.theme = 'yemast'
        this.data = null
        this.window = $window
        this.service = warningService

    }

    $onInit(){
        this.window.localStorage.setItem('warStartDate',this.terms.startDate)
        this.window.localStorage.setItem('warEndDate',this.terms.endDate)
        // console.log(this.terms)
        this.fetchData()
    }

    $doCheck(){

    }

    fetchData(){
        if(!checkParamReady.apply(this)){
            return
        }
        // console.log(this.terms, 1111);
        let params = {
            startDate:this.terms.startDate,
            endDate:this.terms.endDate,
            sectionId:this.terms.sectionId,
            workAreaId:this.terms.workAreaId,
            materialType:this.terms.materialType,
        }
        this.service.hisWarningList(params).then(res=>{
            this.data = res
            console.log(this.data)
        });
    }

    // 返回上级目录
    goBack(){
        history.back();
    }
}

HistoryWarningList.$inject = ['warningService','$window']