/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-05-07 09:48:20
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-08-15 10:16:55
 * @FilePath: \roadcqc-mobile\src\pages\lq\realtime\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import RealTimeMixplantInfo from './mixplant.ctrl'
import RealTimeDashBoardInfo from './dashboard.ctrl'
import RealTimePaveInfo from './paveInfo.ctrl'
import RealTimeProgress from './progress.ctrl'
import RealTimeProgressHorizontal from './progressHorizontal.ctrl'
import RealTimeMap from './map.ctrl'
import RealMainCtrl from './realTime.ctrl'
import realTimeService from './service'
import LqPaveRealTimeCloudPictureCtrl from './paverealtimecloudpicture.ctrl'
import LqRollRealTimeCloudPictureCtrl from './rollrealtimecloudpicture.ctrl'
import LqCheckVideoCtrl from './checkVideo.ctrl'
import LqCheckVideoAfterCtrl from './checkVideoAfter.ctrl'
// import 'angular-touch' //CDN

export default angular.module('control.lq.realTime',
    [
        realTimeService,
        'ngTouch',
    ])
    .config(function ($stateProvider) {
        $stateProvider
            .state('lq.realTime', {
                url: '/realTime',
                template: '<lq-real-main current-work-area="vm.currentWorkArea" ></lq-real-main>',
                title:'沥青实时'
            })
            .state('lqPaveRealTimeCloudPicture', {
                url: '/lqPaveRealTimeCloudPicture',
                template: '<lq-pave-real-time-cloud-picture currentWorkArea="vm.currentWorkArea"></lq-pave-real-time-cloud-picture>'
            })
            .state('lqRollRealTimeCloudPicture', {
                url: '/lqRollRealTimeCloudPicture',
                template: '<roll-real-time-cloud-picture material-type="lq"></roll-real-time-cloud-picture>'
            })
            .state('swRollRealTimeCloudPicture', {
                url: '/swRollRealTimeCloudPicture',
                template: '<roll-real-time-cloud-picture material-type="sw"></roll-real-time-cloud-picture>'
            })
            .state('lqCheckVideo', {
                url: '/lqCheckVideo',
                template: '<check-video material-type="lq"></check-video>'
            })
            .state('lqCheckVideoAfter', {
                url: '/lqCheckVideoAfter',
                template: '<check-video-after material-type="lq"></check-video-after>'
            })
            .state('swCheckVideo', {
                url: '/swCheckVideo',
                template: '<check-video material-type="sw"></check-video>'
            })
            .state('swCheckVideoAfter', {
                url: '/swCheckVideoAfter',
                template: '<check-video-after material-type="sw"></check-video-after>',
                params: {'url': null,'title':null},
            })
            .state('progressHorizontal', {
                url: '/progressHorizontal/:terms',
                template: '<progress-horizontal></progress-horizontal>'
            })
    })
    .component('lqRealMain', {
        template: require('./realTime.html'),
        controller: RealMainCtrl,
        controllerAs: 'vm',
        bindings: {
            currentWorkArea: '<',
        }
    })
    .component('lqRealTimeMix', {
        template: require('./mixplant.html'),
        controller: RealTimeMixplantInfo,
        controllerAs: 'vm',
        bindings: {
            terms: '<',
        }
    })
    .component('lqRealTimeDash', {
        template: require('./dashboard.html'),
        controller: RealTimeDashBoardInfo,
        controllerAs: 'vm',
        bindings: {
           terms:'<'
        }
    })
    .component('lqRealTimePave', {
        template: require('./paveInfo.html'),
        controller: RealTimePaveInfo,
        controllerAs: 'vm',
        bindings: {
           terms:'<'
        }
    })
    .component('lqRealTimeProgress', {
        template: require('./progress.html'),
        controller: RealTimeProgress,
        controllerAs: 'vm',
        bindings: {
           terms:'<'
        }
    })
    .component('progressHorizontal', {
        template: require('./progressHorizontal.html'),
        controller:RealTimeProgressHorizontal,
        controllerAs: 'vm',
        bindings:{
            materialType:'@'
        }
    })
    .component('lqRealTimeMap', {
        template: require('./map.html'),
        controller: RealTimeMap,
        controllerAs: 'vm',
        bindings: {
           terms:'<'
        }
    })
    .component('lqPaveRealTimeCloudPicture', {
        template: require('./paverealtimecloudpicture.html'),
        controller:LqPaveRealTimeCloudPictureCtrl,
        controllerAs: 'vm',
        bindings: {
            currentWorkArea: '<'
        }
    })
    .component('rollRealTimeCloudPicture', {
        template: require('./rollrealtimecloudpicture.html'),
        controller:LqRollRealTimeCloudPictureCtrl,
        controllerAs: 'vm',
        bindings:{
            materialType:'@'
        }
    })
    .component('checkVideo', {
        template: require('./checkVideo.html'),
        controller:LqCheckVideoCtrl,
        controllerAs: 'vm',
        bindings:{
            materialType:'@'
        }
    })
    .component('checkVideoAfter', {
        template: require('./checkVideoAfter.html'),
        controller:LqCheckVideoAfterCtrl,
        controllerAs: 'vm',
        bindings:{
            materialType:'@'
        }
    })
    .name
