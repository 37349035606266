/**
 *
 */

import {addDate, addMinute, dateFormat,pileNo2Str} from "_services/transformers";

export default class SwRealTimePaveInfo {
    constructor(swRealTimePaveService,$interval,$state,systemService,$rootScope){
        this.service = swRealTimePaveService

        this.interval = $interval

        this.state = $state

        this.systemService = systemService

        this.rootScope = $rootScope

        this.info = {}

        this.rollers = []

        this.pavers = []

        this.hasWork = false

        this.currentPileNoStr = ''

        this.col = 4

        //30 *6 分 内
        this.timeRange = -60 * 5
        //20 秒
        this.period = 20 * 1000
        
        this.isDp=""

        // 主用于彩图
        this.rootScope.cloudpicture = {}

        // 视频显示影藏
        this.videoSite1="0"
        this.videoSite2="0"

    }

    $doCheck(){
        //工区变化时重新查询
        if(this.preWorkAreaId !== this.terms.workAreaId){
            this.fetchWorkAreaDp()
            this.fetchLayers().then(res => this.fetchRollerType());
        }
        this.preWorkAreaId = this.terms.workAreaId

        let workArea = this.rootScope.currentWorkAreaClassify 
        if(workArea != null){
            this.videoSite1=workArea.videoSite1
            this.videoSite2=workArea.videoSite2
        } 
    }    

    $onInit(){
        this.startTimer()
    }

    $onDestroy(){
        console.log('paver   onDestry')
        this.interval.cancel(this.timer)
    }


    startTimer(){
        let that = this
        this.timer =   this.interval(function(){
            that.fetchWorkAreaDp()
        },this.period)
    }

    fetchData(){
        if(!this.paramWrong(this.terms)){
            return
        }

        let terms = this.terms

        let now = new Date()
        let param = {
            'materialType':'sw',
            sectionId:terms.sectionId ,
            workAreaId:terms.workAreaId,
            startDateTime:dateFormat(addMinute(now,this.timeRange),'yyyy-MM-dd hh:mm:00'),
            endDateTime:dateFormat(now,'yyyy-MM-dd hh:mm:00')
        }

        this.service.fetchOnlineMachineInfo(param).then(info=>{
            this.info = info ;

            if(info.pavers && info.pavers.length>0){
                this.pavers = info.pavers
            }else{
                this.pavers = []
            }

            if(info.rollers && info.rollers.length>0){
                this.rollers = info.rollers.map(item => {
                    item.pileNo = pileNo2Str(item.pileNo)
                    return item
                })
            }else{
                this.rollers=[]
            }

            this.hasWork = info.hasWork

            if(info.paverAvgInfo){
                this.paverAvgInfo = info.paverAvgInfo
            }else{
                this.paverAvgInfo = []
            }

            this.currentPileNoStr = info.currentPileNoStr

            this.col = info.col

        })
    }


    paramWrong(terms){
        if(terms == null){
            return false
        }
        if(terms.workAreaId == null || terms.sectionId == null){
            return false
        }
        return true
    }

    /**
     * 获取当前工程、标段、工区下的层位信息
     * 建议: 获取层位信息可以提到上层main.ctrl.js中，因为每个模块基本上都有要使用层位信息，
     * 现在在每个用到层位信息的模块中都要去主动获取当前的工区下的层位，有点不好，比较乱
     * @author whuab
     */
    /**
     * 获取层位信息
     * @type {{materialType: string, sectionId: *, workAreaId: *, datePart: String}}
     */
    fetchLayers () {
        if(!this.paramWrong(this.terms)){
            return
        }
        console.log('获取层位信息')
        let terms = {};
        terms.dp = false;
        terms.engineerId = this.terms.engineerId;
        terms.materialType = 'sw'
        terms.sectionId = this.terms.sectionId;
        terms.workAreaId = this.terms.workAreaId;
        terms.datePart=dateFormat(new Date(),'yyyy-MM-dd')
        return this.systemService.fetchRollDefaultFilter(terms).then(res => {
            console.log(res)
            // 先清除之前获取的层位信息
            this.rootScope.cloudpicture={}
            if (res.layerList.length > 0) {
                this.rootScope.cloudpicture.layerList = res.layerList
                this.currentLayer = res.layerList[0]
            } else {
                this.currentLayer = {}
            }
            this.rootScope.cloudpicture.currentLayer = this.currentLayer;
            // this.rootScope.cloudpicture.currentRollLayer = this.currentLayer;
            this.rootScope.cloudpicture.engineerId = this.terms.engineerId;
            this.rootScope.cloudpicture.sectionId = this.terms.sectionId;
            this.rootScope.cloudpicture.workAreaId = this.terms.workAreaId;

            console.log(this.rootScope.cloudpicture)
        })
    }

    fetchRollerType() {
        console.log('获取层位信息')
        this.systemService.fetchRollerType(this.terms.workAreaId, 'sw').then(res => {
            console.log(res)
            this.rootScope.cloudpicture.rollerTypeList = res
            if (res != null) {
                this.rootScope.cloudpicture.curRollerType = res[0]
            }
        })
    }


    
    fetchWorkAreaDp() {
        return this.service.fetchWorkAreaDp(this.rootScope.currentWorkAreaClassify.id).then(res => {
            window.sessionStorage.setItem("isDp",res)
            this.isDp = res
            if (this.isDp == false) {
                this.fetchData()
            } else {
                this.fetchWorkAreaDpDetails()
            }
         })
    }

    fetchWorkAreaDpDetails() {
        if(!this.paramWrong(this.terms)){
            return
        }
        let terms = this.terms
        let param = {
            workAreaId:terms.workAreaId,
        }
        this.service.fetchWorkAreaDpInfo(param).then(info => {
            console.log(info)

            this.info = info ;

            this.pavers = info.paver

            this.hasWork = info.work

            this.rollerNum=info.roller.online
            this.rollerAvgInfo = info.roller.deviceList.map(item => {
                item.show = false
                item.style = {
                    left: Math.random() * 80 + '%',
                    top: Math.random() * 80 + '%'
                }
                return item
            })
            this.paverAvgInfo = info.paver.deviceList.map((item,index) => {
                item.show = false
                item.style = {
                    top : index * 80 + 'px'
                }
                return item
            })
            this.middle=info.roller.middle

            // this.currentPileNoStr = info.currentPileNoStr
            // this.paveMinPileNo=info.paveMinPileNo
            // this.paveMaxPileNo=info.paveMaxPileNo
            // this.col = info.col
        })
    }

    /**
     * 跳转到碾压云图
     */
    toRollRealTimeCloudPicture() {
        this.state.go('swRollRealTimeCloudPicture')
    }

    // /**暂无此功能
    //  * 跳转到摊铺云图
    //  */
    // toPaveRealTimeCloudPicture() {
    //     this.state.go('swPaveRealTimeCloudPicture')
    // }

    /**
     * 跳转到视频监控
     */
     toSwCheckVideo() {
        this.state.go('swCheckVideo')
    }
}
SwRealTimePaveInfo.$inject = ['swRealTimePaveService','$interval', '$state', 'systemService', '$rootScope']
