import ctrl from './currentSearch.ctrl'

export default angular.module('component.search.current',[]).component('currentSearch',{
    template:require('./currentSearch.html'),
    controller:ctrl,
    controllerAs:'vm',
    bindings:{
        datePart:'=',
        moduleType:'='
    }
}).name