export default class HomeCtrl {
    constructor(systemService, $state, $rootScope) {

        this.state = $state

        this.service = systemService


        this.rootScope = $rootScope

        this.isProjectChange = false
        this.info = null
        this.opts = null
        this.polylineInfo = []
        this.sectionsInfo={}
        this.color = ['#ac5692', '#ee5b63', '#f9ae6b', '#fbeb64', '#56ae67', '#6d6ba5']
    }

    $onInit() {
        this.fetchProject()
    }

    mapInit(longitude,latitude){
        this.info = null
        this.opts = {
            centerAndZoom: {
                longitude: longitude,
                latitude: latitude,
                zoom: 11
            }
        }
        this.markerOpts = {
            offset: {
                width: 0,
                height: 0
            },
            icon: {
                url: '/assets/img/markers.png',
                size: {
                    width: 19,
                    height: 25
                }
            }
        };

        this.showWindow = function showWindow(item) {
            // console.log(item)
            item.markerOpts = this.markerOpts
            item.sectionsInfo=this.sectionsInfo
            let title="<p style='color: #949494;transform:  (-50%);position:absolute;left: 50%;'>"+"<p style='color: #333333;transform:translateX(-50%)'>"+item.markerOpts.name+"</p><p style='color: #666666;margin:0;transform:translateX(-50%)'>"+item.markerOpts.pileNo+"</p></p>"
            var label = new BMap.Label(title,{offset:new BMap.Size(7,-35)});
            label.setStyle({
                maxWidth:"none",
                border: "0px",
            })

            let new_point = {
                longitude:  this.opts.centerAndZoom.longitude,
                latitude: this.opts.centerAndZoom.latitude,
            }
            var marker = new BMap.Marker(new_point); 
            
            // let marker=document.getElementById('#marker')
            marker.setLabel(label);
            var infoHtml = '<div class="infoWindow">'+
                '<div class="bm_comtent_list"><span>施工单位：</span><span>'+item.sectionsInfo.constructionOfficeNames+'</span></div>'+
                '<div class="bm_comtent_list"><span>监理单位：</span><span>'+item.sectionsInfo.controlOfficeNames+'</span></div>'+
                '<div class="bm_comtent_list"><span>中心实验室：</span><span>'+item.sectionsInfo.centralabOfficeNames+'</span></div>'+
                '<div class="bm_comtent_list"><span>技术服务：</span><span>'+item.sectionsInfo.lqTechnicalServiceOfficeNames+'</span></div>'+
                '<div class="bm_comtent_list"><span>施工标段类型：</span><span>'+item.sectionsInfo.sectionType+'</span></div>'+
                '<div class="bm_comtent_list"><span>时间范围：</span><span>'+item.sectionsInfo.sectionDate+'</span></div>'+
                '<div class="bm_comtent_list"><span>桩号范围：</span><span>'+item.sectionsInfo.constructionBeginPileNo+'</span></div>'
            marker.addEventListener('click',e =>{
                marker.openInfoWindow(new BMap.InfoWindow(infoHtml, {
                    title: item.sectionsInfo.sectionName,
                    offset: {
                        width: 0,
                        height: 0
                    }
                }))
            })

            
        };

        this.polylineOpts={
            strokeColor: '',
            strokeWeight: 8,
            strokeStyle:'dotted'
        }
        
    }
    fetchWorkAreaDetail() {
        if(this.rootScope.workAreaList.length == 0){
            return
        }
        let colorNumber = 0
        let sectionIds = [];
        let sections = []
        this.rootScope.workAreaList.forEach(item=>{
            sectionIds.push(item.section)
        })

        var obj={}
        for(var i=0;i<sectionIds.length;i++){
            if(!obj[sectionIds[i].id]){
                sections.push(sectionIds[i]);
                obj[sectionIds[i].id] = true;
            }
        }
        this.polylineInfo = []
        sections.forEach(item => {
            this.service.fetchSectionDetail({'sectionId':item.id}).then(res =>{
                //标段详情
                this.sectionsInfo={
                    constructionOfficeNames	 : res.constructionOfficeNames,
                    controlOfficeNames	: res.controlOfficeNames,
                    centralabOfficeNames: res.centralabOfficeNames,
                    lqTechnicalServiceOfficeNames: res.lqTechnicalServiceOfficeNames,
                    swTechnicalServiceOfficeNames: res.swTechnicalServiceOfficeNames,
                    sectionType: res.sectionType == 1 ?'路基标':'路面标',
                    constructionBeginPileNo	: res.constructionBeginPileNo +"~"+res.constructionEndPileNo,
                    sectionDate: res.sectionBeginDate+"~"+res.sectionEndDate,
                    sectionName:item.sectionName
                }

                for(let i in this.sectionsInfo){
                    if(this.sectionsInfo[i] == null || this.sectionsInfo[i].indexOf('null') > -1){
                        this.sectionsInfo[i] = '无'
                    }
                }

                //标段点坐标
                if (res) {
                    // console.log(res)
                    if(colorNumber < 7){
                        this.polylineOpts.strokeColor = this.color[colorNumber]
                        colorNumber ++
                    }else{
                        colorNumber = 0
                        this.polylineOpts.strokeColor = this.color[0]
                    }
                    var pointArr = [];
                    for(var k = 0; k < res.rangList.length; k++) {
                        var sectionRang = res.rangList[k];
                        var lng_lat_1 = this.wgs84togcj02(sectionRang.northPoint ,sectionRang.eastPoint)
                        var lng_lat_2 = this.gcj02tobd09(lng_lat_1[0],lng_lat_1[1])
                        pointArr.push({
                            lng: lng_lat_2[0],
                            lat: lng_lat_2[1]
                        });
                    }
                    // 生成坐标点
                    var trackPoint = []
                    for(var i = 0, j = pointArr.length; i < j; i++) {
                        trackPoint.push({'longitude':pointArr[i].lng, 'latitude':pointArr[i].lat});
                    }
                    this.markerOpts.name = item.sectionName;
                    if(item.constructionBeginPileNoStr == null){
                        item.constructionBeginPileNoStr = '无'
                    }
                    if(item.constructionEndPileNoStr == null){
                        item.constructionEndPileNoStr = '无'
                    }
                    this.markerOpts.pileNo = item.constructionBeginPileNoStr+'~'+item.constructionEndPileNoStr;
                    let polylineInfoItem = {
                        polylinePoints:trackPoint,
                        polylineOpts:this.polylineOpts,
                        location:this.opts.centerAndZoom,
                        markerOpts:this.markerOpts,
                        sectionsInfo:this.sectionsInfo
                    };
                    this.polylineInfo.push(polylineInfoItem)
                    this.opts = {
                        centerAndZoom: {
                            longitude: polylineInfoItem.location.longitude,
                            latitude: polylineInfoItem.location.latitude,
                            zoom: 12
                        }
                    }
                }
            })
        })

    }
    transformlat(lng, lat) {
        let PI = 3.1415926535897932384626;
        var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
        ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0;
        ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0;
        return ret
    }
    transformlng(lng, lat) {
        let PI = 3.1415926535897932384626;
        var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
        ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0;
        ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0;
        return ret
    }
    wgs84togcj02(lng, lat) {
        let PI = 3.1415926535897932384626;
        let a = 6378245.0;
        let ee = 0.00669342162296594323;
        if (this.out_of_china(lng, lat)) {
            return [lng, lat]
        }else {
            var dlat = this.transformlat(lng - 105.0, lat - 35.0);
            var dlng = this.transformlng(lng - 105.0, lat - 35.0);
            var radlat = lat / 180.0 * PI;
            var magic = Math.sin(radlat);
            magic = 1 - ee * magic * magic;
            var sqrtmagic = Math.sqrt(magic);
            dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
            dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
            var mglat = lat + dlat;
            var mglng = lng + dlng;
            return [mglng, mglat]
        }
    }
    gcj02tobd09(lng, lat) {
        let x_PI = 3.14159265358979324 * 3000.0 / 180.0;
        var z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI);
        var theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI);
        var bd_lng = z * Math.cos(theta) + 0.0065;
        var bd_lat = z * Math.sin(theta) + 0.006;
        return [bd_lng, bd_lat]
    }
    out_of_china(lng, lat) {
        return (lng < 72.004 || lng > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false);
    }


    get projectList() {
        return this.rootScope.projectList
    }

    set projectList(l) {
        this.rootScope.projectList = l
    }

    get workAreaList() {
        return this.rootScope.workAreaList
    }

    set workAreaList(l) {
        this.rootScope.workAreaList = l
    }

    //示例 监控工程变化 用set get 实现
    set currentProject(cur) {
        this.rootScope.currentProject = cur
        //获取能进入的材料类型
        this.fetchMaterialType()

        this.isProjectChange = true
        //级联工区
        this.fetchWorkArea()
        let arr = []
        if(cur.baiduPoint !==null){
            arr = cur.baiduPoint.split(',')
        }else{
            arr = [121.71806,31.234604]
        }
        this.mapInit(arr[0],arr[1])

    }

    get currentProject() {
        return this.rootScope.currentProject
    }


    set currentWorkArea(w) {
        this.rootScope.currentWorkArea = w
        this.currentSection = w && w.section
        if (this.rootScope.currentProject.id != null) {
            //获取标段信息
            this.fetchWorkAreaDetail()
        }
    }


    get currentWorkArea() {
        return this.rootScope.currentWorkArea
    }

    set currentSection(s) {
        this.rootScope.currentSection = s
    }

    get currentSection() {
        return this.rootScope.currentSection
    }
    classifyClick(){
        this.rootScope.currentProjectClassify = null
    }
    fetchProject() {
        if (this.rootScope.projectList != null) {
            this.fetchWorkArea()
            let arr;
            if(this.rootScope.currentProject.baiduPoint !==null){
                arr = this.rootScope.currentProject.baiduPoint.split(',')
            }else{
                arr = [121.71806,31.234604]
            }
            this.mapInit(arr[0],arr[1])
            return
        }
        this.service.fetchProject(null).then(res => {
            this.rootScope.projectList = res
            if (Array.isArray(this.projectList) && this.projectList.length > 0) {
                this.currentProject = this.rootScope.currentProject || this.projectList[0]
            } else {
                this.currentProject = null
            }
        })
    }

    fetchMaterialType(){
        if(this.currentProject == null){
            return
        }

        this.service.fetchMaterialType(this.currentProject.id).then(res => {
            this.rootScope.materialTypeShow = res
        })
    }

    fetchWorkArea() {
        if (this.currentProject == null) {
            return
        }

        this.rootScope.currentWorkArea = null

        if (this.currentProject.id == null) {
            return
        }
        return this.service.fetchWorkArea(this.currentProject.id, null).then(res => {
            this.rootScope.workAreaList = res || []
            if (Array.isArray(res) && res.length > 0) {
                this.currentWorkArea = this.rootScope.currentWorkArea || res[0]
                /*转换选择环节展示数据*/
                let arr = [];
                if(this.currentWorkArea.chainList){
                    for(let i=0;i<this.currentWorkArea.chainList.length;i++){
                        let obj={};
                        if(this.currentWorkArea.chainList[i]=="mixplant"){
                            obj.name="拌和";
                            obj.value=this.currentWorkArea.chainList[i]
                        }else if(this.currentWorkArea.chainList[i]=="transport"){
                            obj.name="运输";
                            obj.value=this.currentWorkArea.chainList[i]
                        }else if(this.currentWorkArea.chainList[i]=="pave"){
                            obj.name="摊铺";
                            obj.value=this.currentWorkArea.chainList[i]
                        }else {
                            obj.name="碾压";
                            obj.value='roll'
                        }
                        arr.push(obj)
                    }
                    this.currentWorkArea.chainList  = arr;
                }
            } else {
                this.currentWorkArea = null
            }
        })
    }


    get materialTypeShow(){
        return this.rootScope.materialTypeShow
    }
    set materialTypeShow(d){
        this.rootScope.materialTypeShow = d
    }

}

HomeCtrl.$inject = ['systemService', '$state', '$rootScope']