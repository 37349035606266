export default  class HistoryIndex  {
    constructor($stateParams,warningService,$rootScope){
        this.service = warningService;
        this.stateParams = $stateParams
        this.service = warningService;
        this.rootScope = $rootScope

        this.termsStr = this.stateParams.terms

        this.terms = JSON.parse(this.termsStr)
        this.untreated = 0
    }
    $onInit(){
        this.getData()
    }
    getData(){
        let terms = this.terms
        let param = {
            'sectionId':terms.sectionId,
            'engineerId': terms.engineerId,
            'workAreaId': terms.workAreaId,
            'startDate': terms.startDate,
            'endDate': terms.endDate,
            'dimension':terms.dimension,
            'materialType':terms.materialType
        }
        if(param.workAreaId == null){
            return
        }
        if(terms.dimension == 1){
            param.layerId = terms.layerId
        }
        //预警
        this.service.warningInfo(param).then(res => {
            this.warningInfo = res;
        })
        // this.service.sumWarningList(this.terms).then(res=>{
        //     this.untreated = res.unProcessCount
        // });
    }
    goBack(){
        history.back();
    }

    get untreated (){
        return  this.rootScope.untreated
    }


    set untreated(l){
        this.rootScope.untreated = l

    }


}

HistoryIndex.$inject = ['$stateParams','warningService','$rootScope']