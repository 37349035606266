/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-06-14 10:41:53
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2024-07-01 11:23:49
 * @FilePath: \roadcqc-mobile\src\pages\lq\realtime\checkVideo.ctrl.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import BaseCtrl from "_pages/common/baseCtrl";
import {dateFormat} from "_services/transformers";

export default class LqRollRealTimeCloudPictureCtrl extends BaseCtrl {
    constructor($scope, $rootScope,$stateParams){
        super();
        this.scope = $scope;
        this.rootScope = $rootScope;
        this.stateParams=$stateParams;
        //console.log(this.rootScope);
        console.log(this.stateParams);
        this.rollCloudPicture = {
            // 工区
            workAreaId: this.rootScope.cloudpicture?this.rootScope.cloudpicture.workAreaId:this.rootScope.currentWorkArea?this.rootScope.currentWorkArea.id:'',

        };

        this.url=`http://218.2.99.139:17430/#/?workareaId=${this.rollCloudPicture.workAreaId}&site=2`
        if(this.stateParams.url){
            this.url = this.stateParams.url ;
            document.title = this.stateParams.title ;
        }
        //console.log(this.url)
    }

    $onInit() {
        setTimeout(()=>{
            if(document.getElementById('iframe')){
                document.getElementById('iframe').src=this.url
            }
        },1000)
    }

    /**
     * 返回上级
     */
    goBack(){
        console.log('in')
        history.back();
    }
}

LqRollRealTimeCloudPictureCtrl.$inject = ['$scope', "$rootScope","$stateParams"]
