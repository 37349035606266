export default class SnRealTimeService{
    constructor(http){
        this.http = http
    }

    fetchMixplantList(workAreaId,timeRange){
        var ran  = Math.random()*10;//随机数
        return this.http.get('/sn/mixplant/realTimeMixplant',{id:workAreaId,timeRange,ran})
            .then(res=>res.data);
    }

    fetchLabelDashBoard(terms){
        return this.http.get('/sn/mixplant/keyLabel',terms)
            .then(res=>{
                let data = res.data

                data.weight = res.data.weight.toFixed(1)

                return data
            });
    }

    fetchRealTimeOutput(terms){
        return this.http.get('/sn/mixplant/realTimeOutput',terms)
            .then(res=>res.data);
    }
}
SnRealTimeService.$inject = ['http']
