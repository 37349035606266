class SystemService {
    /**
     */
    constructor(http) {
        this.http = http
        this.color = ['#565d87', '#eeb800', '#e75047', '#565d87', '#eeb800', '#e75047']
    }

    /**
     * 获取施工日历
     */
    fetchWorkCalendar(param){
        return this.http
            .get('/workCalendar/all',param)
            .then(res => res.data)
    }

    /*
     *  获取首页工程标段
     */
    fetchWorkAreaDetail(param){
        return this.http
            .get('/global/workArea/detail',param)
            .then(res => res.data)
    }
    /*
     *  获取首页标段详细坐标
     */
    fetchSectionRange(param){
        return this.http
            .get('/global/section/sectionRange',param)
            .then(res => res.data)
    }

    /*
     *  获取首页标段详情
     */
    fetchSectionDetail(param){
        return this.http
            .get('/global/section/info',param)
            .then(res => res.data)
    }

    /**
     * 获取工程列表
     */
    fetchProject(mType) {
        return this.http
            .get('/global/listEngineer' ,{
                mType: mType
        })
            .then(res => res.data)
    }

    /**
     * 获取工区列表
     */
    fetchWorkArea(engId, mType) {
        if (engId == null) {
            return Promise.reject([])
        }
        return this.http
            .get('/global/listWorkArea', {engId: engId, mType: mType})
            .then(res => res.data)
    }

    /**
     * 根据工区判断是否为低配工区
     */
    fetchWorkAreaDp(workareaId) {
        if (workareaId == null) {
            return 
        }
        return this.http
            .get('/base/workarea/checkForTime', {workareaId: workareaId})
            .then(res => res.data)
    }

    /*
      *  获取能进入的材料类型
     */
    fetchMaterialType(engId) {
        if (engId == null) {
            return Promise.reject([])
        }
        return this.http
            .get('/auth/workarea', {engId: engId})
            .then(res => res.data)
    }


    /**
     * 获取拌和楼列表
     */
    fetchMixplant(workAreaId, mType) {
        if (workAreaId == null) {
            return Promise.reject([])
        }
        return this.http
            .get('/global/listMixplant', {workAreaId: workAreaId, mType: mType})
            .then(res => res.data)
    }


    /**
     * 获取压路机类型
     */
    fetchRollerType(workAreaId, mType,layerId) {
        return this.http
            .get('/global/listRollerType', {
                workAreaId: workAreaId,
                materialType: mType,
                layerId:layerId
            })
            .then(res => res.data)
    }

    fetchRollerTypeAndLayerFromRealTime(workAreaId, mType) {
        return this.http
            .get('/roll//lq/getLayerAndRollerType', {
                workAreaId: workAreaId,
                materialType: mType
            })
            .then(res => res.data)
    }

    /**
     * 获取指定工区下的压路机列表
     */
    fetchRollerLst(workAreaId,rollType) {
        return this.http
            .get('/global/rollerList', {
                workAreaId: workAreaId,
                rollType:rollType
            })
            .then(res => res.data)
    }

    fetchStrengths(workAreaId) {
        return this.http.get("/sn/listStrengths", {
            workAreaId
        }).then(res => res.data)
    }

    /**
     * 获取沥青拌和楼默认查询条件
     */
    fetchLqDefaultForm(param) {
        return this.http
            .get('/global/defaultForm/lq/mixplant', param)
            .then(res => res.data)
    }
    /**
     * 获取汇总全局数据
     */
    fetchworkCalendarRange(param){
        return this.http
            .get('/workCalendar/range', param)
            .then(res => res.data)
    }

    fetchSwDefaultForm(param) {
        return this.http
            .get('/global/defaultForm/sw/mixplant', param)
            .then(res => res.data)
    }

    fetchSnDefaultForm(param) {
        return this.http
            .get('/global/defaultForm/sn/mixplant', param)
            .then(res => res.data)
    }

    /**
     * 获取沥青拌和楼默认过滤条件
     * 层位 + 混合料
     */
    fetchLqDefaultFilter(param) {
        return this.http
            .get('/global/defaultForm/lq/mixplant/filterForm', param)
            .then(res => res.data)
    }

    fetchSwDefaultFilter(param) {
        return this.http
            .get('/global/defaultForm/sw/mixplant/filterForm', param)
            .then(res => res.data)
    }

    fetchSnDefaultFilter(param) {
        return this.http
            .get('/global/defaultForm/sn/mixplant/filterForm', param)
            .then(res => res.data)
    }

    /**
     * 获取沥青摊铺默认查询条件
     */
    fetchPaveDefaultForm(param, materialType) {
        return this.http
            .get('/global/defaultForm/' + materialType + '/pave', param)
            .then(res => res.data)
    }

    /**
     * 获取沥青碾压默认查询条件
     */
    fetchRollDefaultForm(param, materialType) {
        return this.http
            .get('/global/defaultForm/' + materialType + '/roll', param)
            .then(res => res.data)
    }

    /**
     * 碾压 默认过滤条件
     */
    fetchRollDefaultFilter(terms) {
         return this.http
            .get('/global/defaultForm/roll/filterForm',terms)
            .then(res => res.data)
    }
    /**
     * 摊铺 默认过滤条件
     */
    fetchPaveDefaultFilter(terms) {
        return this.http
            .get('/global/defaultForm/pave/filterForm', terms)
            .then(res => res.data)
    }

    /**
     * 获取沥青摊铺云图
     */
    fetchPaveCloudPicture(searchForm, mType) {
        return this.http
            .get('/' + mType + '/pave/byTime/cloudPicture', searchForm)
            .then(res => res.data);
    }

    /**
     * 获取沥青碾压云图
     */
    fetchRollCloudPicture(searchForm, mType) {
        return this.http
            .get('/' + mType + '/roll/byTime/cloudPicture/' + searchForm.category, searchForm)
            .then(res => res.data);
    }
    /*
    *   获取首页地图
     */
    fetchProjectInfo(params) {
        return this.http.get('/project/info', params).then(res => {
            let info = res.data
            info.style = `width:${info.totalPercent}%;background-color: '#3cb04b'`
            if (Array.isArray(info.sectionList)) {
                info.sectionList.forEach(sec => {
                    sec.style = `width: ${sec.totalPercent}%;background-color: '#3cb04b'`
                    //给层位设置颜色
                    if (Array.isArray(sec.layers)) {
                        sec.layers.forEach((l, idx) => {
                            l.color = this.color[idx]
                            l.style = `width: ${l.value}%;background-color: ${l.color}`
                        })
                    }
                })
            }
            if (Array.isArray(info.layers)) {
                info.layers.forEach((l, idx) => {
                    l.color = this.color[idx]
                    l.style = `width: ${l.value}%;background-color: ${l.color}`
                })
            }
            return info
        })
    }
}

SystemService.$inject = ['http']
export default SystemService
