export default  class CurrentIndex {
    constructor($state,$stateParams,warningService){
        this.service = warningService;
        this.stateParams = $stateParams
        this.state = $state
        this.service = warningService;

        this.termsStr = this.stateParams.terms

        this.terms = JSON.parse(this.termsStr)
    }

}

CurrentIndex.$inject = ['$state','$stateParams','warningService']