/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-08-05 14:01:54
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-08-25 16:14:34
 * @FilePath: \roadcqc-mobile\src\pages\lq\realtime\dashboard.ctrl.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {dateFormat} from "_services/transformers";

export default class LqMixplantDashBoardCtrl{
    constructor(lqRealTimeService){
        this.service = lqRealTimeService
        this.data = {}
    }

    $doCheck(){
        //当且仅当换拌和楼是查询
        if(this.terms == null){
            return
        }
        
        if(this.preMixplantId !== this.terms.mixplantId){
            // console.log(this.terms)
            this.data =this.terms
            // this.fetchAnalyzeData()
        }
        this.preMixplantId = this.terms.mixplantId
    }

    // 8608bug
    fetchAnalyzeData(){
        let terms = this.terms
        console.log(this.terms)
        let now = new Date()
        let today = dateFormat(now,'yyyy-MM-dd')
        let param = {
            'sectionId':terms.sectionId,
            'workAreaId':terms.workAreaId,
            'mixplantId':terms.mixplantId,
            'mixtureId':terms.mixtureId,
            'startDate':today,
            'endDate':today
        }
        this.service.fetchLabelDashBoard(param).then(res=>{
            this.data = res
            console.log(this.data)
        })
    }
}
LqMixplantDashBoardCtrl.$inject = ['lqRealTimeService']