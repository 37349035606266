

//为了减少下层的路由配置 再此统一配置

export default angular.module('control.lq.transport', [])
    .service('transportService',require('./service/transport.service').default)
    .component('traCur', {
        template: require('./current/current.html'),
        controller: require('./current/current.ctrl').default,
        controllerAs: 'vm',
        bindings: {
            currentWorkArea: '<',
            materialType:'@',
            byWhat:'@'
        }
    })
    .name