
export default  class HistoryIndex  {
    constructor($stateParams,warningService){
        this.service = warningService;
        this.stateParams = $stateParams
        this.service = warningService;

        this.termsStr = this.stateParams.terms

        this.terms = JSON.parse(this.termsStr)
    }




}

HistoryIndex.$inject = ['$stateParams','warningService']