 export default  class CurrentWarningListBox {
    constructor($state,$stateParams,warningService){
        this.stateParams = $stateParams
        this.state = $state
        this.service = warningService;

        this.termsStr = this.stateParams.terms

        this.terms = JSON.parse(this.termsStr)


    }




}

CurrentWarningListBox.$inject = ['$state','$stateParams','warningService']