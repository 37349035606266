export default class ProjectService {
    constructor(http) {
        this.http = http;
        this.color = ['#565d87', '#eeb800', '#e75047', '#565d87', '#eeb800', '#e75047']
    }

    fetchProjectInfo(params) {
        return this.http.get('/project/info', params).then(res => {
            let info = res.data
            info.style = `width:${info.totalPercent}%;background-color: '#3cb04b'`
            if (Array.isArray(info.sectionList)) {
                info.sectionList.forEach(sec => {
                    sec.style = `width: ${sec.totalPercent}%;background-color: '#3cb04b'`
                    //给层位设置颜色
                    if (Array.isArray(sec.layers)) {
                        sec.layers.forEach((l, idx) => {
                            l.color = this.color[idx]
                            l.style = `width: ${l.value}%;background-color: ${l.color}`
                        })
                    }
                })
            }
            if (Array.isArray(info.layers)) {
                info.layers.forEach((l, idx) => {
                    l.color = this.color[idx]
                    l.style = `width: ${l.value}%;background-color: ${l.color}`
                })
            }
            return info
        })
    }
}

ProjectService.$inject = ['http']