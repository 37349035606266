import checkParamReady from "_components/warning/warning_fn";

export default  class HistoryPanel{
    constructor($state,$stateParams,warningService){
        this.service = warningService;
        this.state = $state
        this.data = {}
    }

    toLevel(level,model) {
        if (this.terms === null) {
            return
        }
        this.terms.level = level
        if(this.terms.materialType == undefined){
            this.terms.materialType = this.materialType
        }
        if(this.terms.moduleType == undefined){
            this.terms.moduleType = this.moduleType
        }
        this.terms.model = model
        this.terms.datePart = null
        this.state.go('historyWarningListBox', {terms: JSON.stringify(this.terms)})

    }
    toWarningHisIndex(){
        if(this.terms === null){
            return
        }
        if(this.terms.materialType == undefined){
            this.terms.materialType = this.materialType
        }
        if(this.terms.moduleType == undefined){
            this.terms.moduleType = this.moduleType
        }
        this.state.go('warningHisIndex',{terms:JSON.stringify(this.terms)})
    }
    $doCheck(){
        if(this.terms == null){
            return
        }
        if (this.preWorkAreaId !== this.terms.workAreaId) {
            this.warningStatistics()
        }else
        //父组件 变更混合料时 查询
        if (this.preDatePart !== this.terms.datePart || this.preBeginDate !== this.terms.beginDate || this.preEndDate !== this.terms.endDate) {
            // console.log('mixture change:' + this.terms.mixtureId )
            this.warningStatistics()
        } else if (this.preMaterialType !== this.materialType) {
            this.warningStatistics()
        } else if (this.preModuleType !== this.moduleType) {
            this.warningStatistics()
        } else if (this.preEndDate !== this.terms.endDate || this.preStartDate !== this.terms.startDate) {
            this.warningStatistics()
        }
        this.preWorkAreaId = this.terms.workAreaId
        this.preDatePart = this.terms.datePart ;
        this.preBeginDate = this.terms.beginDate
        this.preEndDate = this.terms.endDate
        this.preStartDate = this.terms.startDate
        this.preMaterialType = this.materialType
        this.preModuleType = this.moduleType
        this.terms.datePart = null
    }

    get moduleTypeCN(){
        if(this.moduleType==='mixplant'){
            return "拌和"
        }
    }

    warningStatistics(){
        if(!checkParamReady.apply(this)){
            return;
        }
        if(this.terms.materialType == undefined){
            this.terms.materialType = this.materialType
        }
        if(this.terms.moduleType == undefined){
            this.terms.moduleType = this.moduleType
        }
        let terms = this.terms
        let param = {
            'engineerId':terms.engineerId,
            'sectionId':terms.sectionId,
            'workAreaId':terms.workAreaId,
            'startDate':terms.startDate,
            'endDate':terms.endDate,
            'materialType':terms.materialType,
            'moduleType':terms.moduleType
        }
        this.service.warningStatistics(param).then(data => {
            // console.log(data,1111);
            this.data = data
        })
    }
}

HistoryPanel.$inject = ['$state','$stateParams','warningService']