/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-08-04 14:53:32
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-11-11 10:39:46
 * @FilePath: \roadcqc-mobile\src\pages\lq\realtime\service\progress.service.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default class progressService{

    //获取当前时间，格式YYYY-MM-DD
    getNowFormatDate() {
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    }

    constructor(http){
        this.http = http
    }

    // 横道图-沥青施工进度
    lqProgressForApp(terms){
        return this.http.get("/lq/pave/progressForApp",terms).then(res=>res.data)
    }
    // 横道图-水稳施工进度
    swProgressForApp(terms){
        return this.http.get("/sw/pave/progressForApp",terms).then(res=>res.data)
    }
    // 横道图-当日施工里程
    countMileDetailList(terms){
        return this.http.get("/index/v2/milestatis/today",terms).then(res=>res.data)
    }
    // 横道图-沥青获取最新的摊铺速度，桩号
    findLastLqPaverData(terms){
        return this.http.get("/lq/complex/findLastLqPaverData",terms).then(res=>res.data)
    }
    // 横道图-水稳获取最新的摊铺速度，桩号
    SWfindLastLqPaverData(terms){
        return this.http.get("/sw/complex/findLastLqPaverData",terms).then(res=>res.data)
    }
    // 横道图-已施工
    milestatisList(terms){
        return this.http.get("/index/v2/milestatis/listV2",terms).then(res=>res.data)
    }
    // 横道图-匝道并线
    roadTypeList(terms){
        return this.http.get("/index/v2/roads/list",terms).then(res=>res.data)
    }
    // 横道图-点击横道图匝道并线返回左右幅
    digitalPlatform(terms){
        return this.http.get("/digitalPlatform/roadType/one",terms).then(res=>res.data)
    }

    // 今日异常情况
    warningStatistics(terms){
        return this.http.get("/warning/statistics",terms).then(res=>res.data)
    }

    // 历史异常情况
    hisWarningListV2(terms){
        return this.http.get("/warning/hisWarningListV2",terms).then(res=>res.data)
    }
}
progressService.$inject = ['http']