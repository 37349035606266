import {dateFormat} from "_services/transformers";

export default class snMixplantDashBoardCtrl{
    constructor(snRealTimeService){
        this.service = snRealTimeService
        this.data = {}
    }

    $doCheck(){
        //当且仅当换拌和楼是查询
        if(this.terms == null){
            return
        }
        if(this.preMixplantId !== this.terms.mixplantId){
            this.fetchAnalyzeData()
        }
        this.preMixplantId = this.terms.mixplantId
    }

    fetchAnalyzeData(){
        let terms = this.terms
        let now = new Date()
        let today = dateFormat(now,'yyyy-MM-dd')
        let param = {
            'sectionId':terms.sectionId,
            'workAreaId':terms.workAreaId,
            'mixplantId':terms.mixplantId,
            'mixtureId':terms.mixtureId,
            'startDate':today,
            'endDate':today
        }
        this.service.fetchLabelDashBoard(param).then(res=>{
            this.data = res
        })
    }
}
snMixplantDashBoardCtrl.$inject = ['snRealTimeService']