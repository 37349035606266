import realTime from './realtime'
import 'angular-echarts-lite'
import '_lib/infographic'
// import 'easy-pie-chart/dist/angular.easypiechart.min' CDN

export default angular.module('control.sn', [
    realTime,
    'easypiechart',
    'angular-echarts-lite'
]).config(function ($stateProvider) {
    $stateProvider
        .state('sn', {
            url: '/sn',
            abstract: true,
            template: '<main-page material-type="sn"></main-page>',
        })
}).config(function ($stateProvider) {
    $stateProvider
        .state('sn.mixplant', {
            url: '/mixplant',
            abstract: true,
            resolve: {
                foo: ['$q','$ocLazyLoad', function ($q,$ocLazyLoad) {
                    let deferred = $q.defer();
                    require.ensure([], function () {
                        let module = require('./mixplant/index.js');
                        $ocLazyLoad.load({
                            name: 'control.sn.mixplant'
                        });
                        deferred.resolve(module);
                    });

                    return deferred.promise;
                }]
            }
        })
        .state('sn.mixplant.current', {
            url: '/current',
            //路由到组件写法
            template: '<sn-mix current-work-area="vm.currentWorkArea" by-what="byTime"></sn-mix>',
            title: '水泥当日分析'
        })
        .state('sn.mixplant.history', {
            url: '/history',
            template: '<sn-mix current-work-area="vm.currentWorkArea" by-what="byDay" ></sn-mix>',
            title: '水泥历史分析'
        })
}).config(function ($stateProvider) {
    $stateProvider
        .state('sn.summary', {
            url: '/summary',
            template: '<sn-sum-main current-work-area="vm.currentWorkArea"  ></sn-sum-main>',
            title:'水泥汇总',
            resolve: {
                foo: ['$q','$ocLazyLoad', function ($q,$ocLazyLoad) {
                    let deferred = $q.defer();
                    require.ensure([], function () {
                        let module = require('./summary/index.js');
                        $ocLazyLoad.load({
                            name: 'control.sn.summary'
                        });
                        deferred.resolve(module);
                    });

                    return deferred.promise;
                }]
            }
        })
}).name
