import './picker.css'
import './angular-locale_zh-cn.js'

import ctrl from './ctrl'

export default angular.module('ym-datePicker', ['ui.bootstrap']).component('datePicker', {
    template: require('./datePicker.html'),
    controller: ctrl,
    controllerAs: 'vm',
    bindings: {
        date: '='
    }
}).name