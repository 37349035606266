export default class RealTimeMap {
    constructor(systemService, $state, $rootScope) {
        this.state = $state
        this.service = systemService
        this.rootScope = $rootScope
    }

    $onInit() {
        // this.fetchProject()
        var map = new BMap.Map("allmap");
        var point = new BMap.Point(116.331398,39.897445);
        map.centerAndZoom(point, 12);
        var marker = new BMap.Marker(point);  // 创建标注
        map.addOverlay(marker); 
    }

    // // 用经纬度设置地图中心点
    theLocation() {
            console.log(1111)
            var map = new BMap.Map("allmap");
            map.centerAndZoom(new BMap.Point(116.331398,39.897445),12);
            map.enableScrollWheelZoom(true);
            map.clearOverlays(); 
            var new_point = new BMap.Point(118.331398,32.897445);
            var marker = new BMap.Marker(new_point);  // 创建标注
            map.addOverlay(marker);              // 将标注添加到地图中
            map.panTo(new_point);      
    }
}

RealTimeMap.$inject = ['systemService', '$state', '$rootScope']