import checkParamReady from "_components/warning/warning_fn";

export default  class WarningStatistics {
    constructor($stateParams,warningService){
        this.stateParams = $stateParams
        this.service = warningService;
        this.termsStr = this.stateParams.terms

        this.terms = JSON.parse(this.termsStr)
    }


    $onInit(){
        console.log('warning statistics init ')
        this.warningStatistics()
    }
    warningStatistics(){

        console.warn("warning statistics change")
        if(!checkParamReady.apply(this)){
            return;
        }

        let terms = this.terms
        let param = {
            'engineerId':terms.engineerId,
            'sectionId':terms.sectionId,
            'workAreaId':terms.workAreaId,
            'datePart':terms.datePart,
            'startDate':terms.startDate,
            'endDate':terms.endDate,
            'materialType':terms.materialType
        }
        if(param.datePart !== null){
            param.startDate = null;
            param.endDate = null
        }
        this.service.warningStatistics(param).then(data=>{
            this.data = data 
        })
    }

    // 返回上级目录
    goBack(){
        history.back();
    }
}

WarningStatistics.$inject = ['$stateParams','warningService']