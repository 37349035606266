export default class SwRealTimeService{
    constructor(http){
        this.http = http
    }

    fetchMixplantList(workAreaId,timeRange){
        var ran  = Math.random()*10;//随机数
        return this.http.get('/sw/mixplant/realTimeMixplant',{id:workAreaId,timeRange,ran})
            .then(res=>res.data);
    }

    fetchLabelDashBoard(terms){
        return this.http.get('/sw/mixplant/keyLabel',terms)
            .then(res=>res.data);
    }

    fetchRealTimeOutput(terms){
        return this.http.get('/sw/mixplant/realTimeOutput',terms)
            .then(res=>res.data);
    }
}
SwRealTimeService.$inject = ['http']