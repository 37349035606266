
/**
 * 获取预警信息
 * 沥青，水稳，水泥共用
 */

import {
    formatOption, moduleTypeCN, warningDegreeCN, warningProcessStatusCN, warningTitle,
    yesOrNotCN
} from "_services/transformers";

export default class WarningService {
    constructor(http) {
        this.http = http;

    }

    /*
    *  预警
    */
    warningInfo(param){
        return this.http.get('/warning/warningInfo', param, res =>res.data)
    }

    warningStatistics(terms){
        return this.http.get("/warning/statistics",terms).then(res=>res.data)
    }

    warningStatisticsAll(terms){
        return this.http.get("/warning/statisticsAll",terms).then(res=>res.data)
    }

    currentWarningList(terms){
        return this.http.get("/warning/currentWarningList",terms).then(res=>{
            if(Array.isArray(res.data.list)){
                res.data.list.forEach(item => {
                    item.title =  warningTitle(item.moduleType,item.level)
                    item.specialistHelpStr = yesOrNotCN(item.specialistHelp);
                    item.warningStatusStr = warningProcessStatusCN(item.warningStatus);
                })
            }
            return res.data
        })
    }
    hisWarningList(terms){
        return this.http.get("/warning/hisWarningList",terms).then(res=>{
            if(Array.isArray(res.data.list)){
                res.data.moduleTypeCN = moduleTypeCN(res.data.moduleType)
                res.data.warningDegreeCN = warningDegreeCN(res.data.level);
                let option = this._boxedDayOption(res.data);
                res.data.chartOption = formatOption(option)
                res.data.list.forEach(item => {
                    if(Array.isArray(item.list)){
                        item.list.forEach(innerItem => {
                            innerItem.title =  warningTitle(innerItem.moduleType,innerItem.level)
                            innerItem.specialistHelpStr = yesOrNotCN(innerItem.specialistHelp);
                            innerItem.warningStatusStr = warningProcessStatusCN(innerItem.warningStatus);
                        })
                    }

                })

            }
            return res.data
        })
    }
    sumWarningList(terms){
        return this.http.get("/warning/hisWarningListSummary",terms).then(res=>{
            if(Array.isArray(res.data.list)){
                res.data.moduleTypeCN = moduleTypeCN(res.data.moduleType)
                res.data.warningDegreeCN = warningDegreeCN(res.data.level);
                let option = this._boxedDayOption(res.data);
                res.data.chartOption = formatOption(option)
                res.data.list.forEach(item => {
                    if(Array.isArray(item.list)){
                        item.list.forEach(innerItem => {
                            innerItem.title =  warningTitle(innerItem.moduleType,innerItem.level)
                            innerItem.specialistHelpStr = yesOrNotCN(innerItem.specialistHelp);
                            innerItem.warningStatusStr = warningProcessStatusCN(innerItem.warningStatus);
                        })
                    }

                })

            }
            return res.data
        })
    }
    warningDetail(id,moduleType){
        return this.http.get("/warning/detail",{"id":id,'moduleType':moduleType}).then(res=>{
            res.data.warningStatusStr = warningProcessStatusCN(res.data.warningStatus);
            return res.data;
        })
    }

    _boxedDayOption(data) {

       return {
            title: {
                show:false,
                text: '预警统计'
            },tooltip: {
               trigger: 'axis'
           },
           legend: {
               left: "right",
               data: ["预警条数"],

           },
            xAxis: {
                splitLine: {
                    show: false,
                    lineStyle:{
                        color: ['#D6D6D6'],
                        width: 1,
                        type: 'solid'
                    }
                },
                axisLine:{
                    show: true,
                    lineStyle:{
                        color:'black',
                    }
                },
                data: data.dates
            },
            yAxis: {
                type: 'value',
                name: '单位：条',
                axisLine:{
                    show: true,
                    lineStyle:{
                        color:'black',
                    }
                }
            },
            series: [{
                name: '预警条数',
                type: 'line',
                data: data.dateWarningCounts
            }]
        }
    }

    uploadFile(engid,file){
        return this.http.post("/index/v2/upload/file?engid="+engid,file).then(res=>{
            return res.data
        })
    }
    submitWarning(params){
        return this.http.post("/warning/warningHandle",params).then(res=>{
            return res.data
        })
    }
}

WarningService.$inject = ['http']

