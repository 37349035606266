import {dateFormat} from "_services/transformers";

export default class DatePickerCtrl {
    constructor($scope,$rootScope) {
        this.scope = $scope
        this.global = $rootScope
        this._dat = ''
        $scope.format = "yyyy-MM-dd";
        $scope.opened=false
        $scope.dateOptions = {
            showWeeks: false,
            dateDisabled: this.isDisabled,
            customClass: this.getDayClass
        }
    }

    $onInit() {

        this.global.dateDisabled = [{
            date:'2019-05-04'
        }]
        this.global.dateCurrent = [{
            date:'2019-05-12',
            status: 'full'
        }]
    }

    $onChanges(){
        this._dat = new Date(this.date)
    }
    open(){
        this.scope.opened = !this.scope.opened
    }
    isDisabled(obj) {
        var date = obj.date,
            mode = obj.mode;
        for(var i=0;i<this.$root.dateDisabled.length;i++){
            var defaultDate = dateFormat(date,'yyyy-MM-dd')
            if(mode === 'day' && this.$root.dateDisabled[i].date == defaultDate){
                return true
            }
        }
        return false;
        // return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }
    getDayClass(data) {
        console.log(this.$root.dateCurrent)
        var date = data.date,
            mode = data.mode;
        if (mode === 'day') {
            var dayToCheck = dateFormat(date,'yyyy-MM-dd')
            console.log(dayToCheck)
            for (var i = 0; i < this.$root.dateCurrent.length; i++) {
                var currentDay = this.$root.dateCurrent[i].date;
                console.log(currentDay)
                if (dayToCheck === currentDay) {
                    return this.$root.dateCurrent[i].status;
                }
            }
        }

        return '';
    }
    change(){
        this.date = dateFormat(this._dat,'yyyy-MM-dd')
    }
}

DatePickerCtrl.$inject = ['$scope','$rootScope']
