
import realTime from './realtime'
import 'angular-echarts-lite'
import '_lib/infographic'
// import 'easy-pie-chart/dist/angular.easypiechart.min' CDN

export default angular.module('control.sw', [
    realTime,
    'easypiechart',
    'angular-echarts-lite'
]).config(function ($stateProvider) {
    $stateProvider
        .state('sw', {
            url: '/sw',
            abstract: true,
            template: '<main-page material-type="sw"></main-page>',
        })
        .state('sw.transport', {
            url: '/transport',
            abstract: true,
        })
        .state('sw.transport.current', {
            url: '/current',
            //路由到组件写法
            template: '<tra-cur current-work-area="vm.currentWorkArea"   material-type="sw" by-what="byTime">',
            title: '水稳运输当日分析'
        })
        .state('sw.transport.history', {
            url: '/history',
            template: '<tra-cur current-work-area="vm.currentWorkArea"   material-type="sw" by-what="byDay">',
            title: '水稳运输历史分析'
        })
}).config(function ($stateProvider) {
        $stateProvider
            .state('sw.mixplant', {
                url: '/mixplant',
                abstract: true,
                resolve: {
                    foo: ['$q','$ocLazyLoad', function ($q,$ocLazyLoad) {

                        let deferred = $q.defer();
                        require.ensure([], function () {
                            let module = require('./mixplant/index.js');
                            $ocLazyLoad.load({
                                name: 'control.sw.mixplant'
                            });
                            deferred.resolve(module);
                        });

                        return deferred.promise;
                    }]
                }
            })
            .state('sw.mixplant.current', {
                url: '/current',
                //路由到组件写法
                template: '<sw-mix current-work-area="vm.currentWorkArea" by-what="byTime" ></sw-mix>',
                title: '水稳当日分析'

            })
            .state('sw.mixplant.history', {
                url: '/history',
                template: '<sw-mix current-work-area="vm.currentWorkArea" by-what="byDay"></sw-mix>',
                title: '水稳历史分析'
            })
}).config(function ($stateProvider) {
    $stateProvider
        .state('sw.pave', {
            url: '/pave',
            resolve: {
                foo: ['$q','$ocLazyLoad', function ($q,$ocLazyLoad) {

                    let deferred = $q.defer();
                    require.ensure([], function () {
                        let module = require('./pave/index.js');
                        $ocLazyLoad.load({
                            name: 'control.sw.pave'
                        });
                        deferred.resolve(module);
                    });

                    return deferred.promise;
                }]
            }
        })
        .state('sw.pave.current', {
            url: '/current',
            //路由到组件写法
            template: '<sw-pave current-work-area="vm.currentWorkArea" by-what="byTime"></sw-pave>',
            title: '水稳摊铺当日分析'
        })
        .state('sw.pave.history', {
            url: '/history',
            template: '<sw-pave current-work-area="vm.currentWorkArea" by-what="byDay"></sw-pave>',
            title: '水稳摊铺历史分析'
        })
}).config(function ($stateProvider) {
    $stateProvider
        .state('sw.roll', {
            url: '/roll',
            resolve: {
                foo: ['$q','$ocLazyLoad', function ($q,$ocLazyLoad) {

                    let deferred = $q.defer();
                    require.ensure([], function () {
                        let module = require('./roll/index.js');
                        $ocLazyLoad.load({
                            name: 'control.sw.roll'
                        });
                        deferred.resolve(module);
                    });

                    return deferred.promise;
                }]
            }
        })
        .state('sw.roll.current', {
            url: '/current',
            //路由到组件写法
            template: '<sw-roll current-work-area="vm.currentWorkArea" by-what="byTime"></sw-roll>',
            title: '水稳碾压当日分析'
        })
        .state('sw.roll.history', {
            url: '/history',
            //路由到组件写法
            template: '<sw-roll current-work-area="vm.currentWorkArea" by-what="byDay"></sw-roll>',
            title: '水稳碾压历史分析'
        })
        .state('swRollCloudPicture', {
            url:'/swRollCloudPicture/:rollCloudPictureRequest',
            template: '<sw-roll-cloud-picture></sw-roll-cloud-picture>'
        })
}).config(function ($stateProvider) {
    $stateProvider
        .state('sw.summary', {
            url: '/summary',
            template: '<sw-sum-main current-work-area="vm.currentWorkArea"  ></sw-sum-main>',
            title:'水稳汇总',
            resolve: {
                foo: ['$q','$ocLazyLoad', function ($q,$ocLazyLoad) {

                    let deferred = $q.defer();
                    require.ensure([], function () {
                        let module = require('./summary/index.js');
                        $ocLazyLoad.load({
                            name: 'control.sw.summary'
                        });
                        deferred.resolve(module);
                    });

                    return deferred.promise;
                }]
            }
        })
}).name
