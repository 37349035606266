/*
 * @Author: zhouhm 1148305943@qq.com
 * @Date: 2022-08-05 14:01:54
 * @LastEditors: zhouhm 1148305943@qq.com
 * @LastEditTime: 2022-08-10 21:02:04
 * @FilePath: \roadcqc-mobile\src\pages\sw\realtime\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import 'angular-touch' //CDN
import RealTimeProgress from '../../lq/realtime/progress.ctrl'
export default angular.module('control.sw.realTime', [
    'ngTouch',
])
    .service('swRealTimeService',require('./service/realTime.service').default)
    .service('swRealTimePaveService',require('./service/paveInfo.service').default)
    .config(function ($stateProvider) {
        $stateProvider
            .state('sw.realTime', {
                url: '/realTime',
                template: '<sw-real-main current-work-area="vm.currentWorkArea" ></sw-real-main>',
                title:'水稳实时'
            })

    })
    .component('swRealMain', {
        template: require('./realTime.html'),
        controller: require('./realTime.ctrl').default,
        controllerAs: 'vm',
        bindings: {
            currentWorkArea: '<',
        }
    })
    .component('swRealTimeMix', {
        template: require('./mixplant.html'),
        controller: require('./mixplant.ctrl').default,
        controllerAs: 'vm',
        bindings: {
            terms: '<',
        }
    })
    .component('swRealTimeDash', {
        template: require('./dashboard.html'),
        controller: require('./dashboard.ctrl').default,
        controllerAs: 'vm',
        bindings: {
            terms:'<'
        }
    })
    .component('swRealTimePave', {
        template: require('./paveInfo.html'),
        controller: require('./paveInfo.ctrl').default,
        controllerAs: 'vm',
        bindings: {
            terms:'<'
        }
    })
    .component('swRealTimeProgress', {
        template: require('../../lq/realtime/progress.html'),
        controller: RealTimeProgress,
        controllerAs: 'vm',
        bindings: {
           terms:'<'
        }
    })
    .name
