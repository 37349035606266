/**
 * 组件 预警看板
 */

import WarningService from './warning.service'
//当日相关控制器
import curPanelCtrl from './current/current_panel.ctrl'
import curIndexCtrl from './current/current_index.ctrl'
import currentWarningListCtrl from './current/current_warning_list.ctrl'
import currentWarningListBoxCtrl from './current/current_warning_list_box.ctrl'
//历史相关控制器
import hisPanelCtrl from './history/history_panel.ctrl'
import hisIndexCtrl from './history/history_index.ctrl'
import historyWarningListCtrl from './history/history_warning_list.ctrl'
import historyWarningListBoxCtrl from './history/history_warning_list_box.ctrl'
//公共相关控制器
import detailCtrl from './warning_detail.ctrl'
import satisticsCtrl from './warning_statistics.ctrl'
//汇总分析相关控制器
import sumSatisticsCtrl from './summary/warning_statistices.ctrl.js'
import sumDetailCtrl from './summary/sum_index.ctrl'
import sumWarningListCtrl from './summary/sum_warning_list.ctrl'
// 处理预警
import warningProcessCtrl from './warningProcess.ctrl'

export default angular.module('road.component.warning', [])
    .service('warningService', WarningService)
    .component('warningCurPanel', {
        template: require('./current/current_panel.html'),
        controller: curPanelCtrl,
        controllerAs: 'vm',
        bindings: {
            materialType: '@',
            moduleType: '<',
            terms: '<'
        }
    })
    .component('warningCurIndex', {
        template: require('./current/current_index.html'),
        controller: curIndexCtrl,
        controllerAs: 'vm'
    })
    .component('currentWarningListBox', {
        template: require('./current/current_warning_list_box.html'),
        controller: currentWarningListBoxCtrl,
        controllerAs: 'vm'

    })
    .component('currentWarningList', {
        template: require('./current/current_warning_list.html'),
        controller: currentWarningListCtrl,
        controllerAs: 'vm',
        bindings: {
            terms: '<'
        }

    })
    .component('warningHisPanel', {
        template: require('./history/history_panel.html'),
        controller: hisPanelCtrl,
        controllerAs: 'vm',
        bindings: {
            materialType: '@',
            moduleType: '<',
            terms: '<'
        }
    })
    .component('warningHisIndex', {
        template: require('./history/history_index.html'),
        controller: hisIndexCtrl,
        controllerAs: 'vm'
    })
    .component('historyWarningListBox', {
        template: require('./history/history_warning_list_box.html'),
        controller: historyWarningListBoxCtrl,
        controllerAs: 'vm'
    })
    .component('historyWarningList', {
        template: require('./history/history_warning_list.html'),
        controller: historyWarningListCtrl,
        controllerAs: 'vm',
        bindings: {
            terms: '<'
        }
    })
    .component('warningDetail', {
        template: require('./warning_detail.html'),
        controller: detailCtrl,
        controllerAs: 'vm',
        bindings: {
        }
    })
    .component('warningStatistics', {
        template: require('./warning_statistics.html'),
        controller: satisticsCtrl,
        controllerAs: 'vm'
    })
    .component('warningSumStatistics', {
        template: require('./summary/warning_statistices.html'),
        controller: sumSatisticsCtrl,
        controllerAs: 'vm',
        bindings: {
            terms: '<'
        }
    })
    .component('warningSumDetail', {
        template: require('./summary/sum_index.html'),
        controller: sumDetailCtrl,
        controllerAs: 'vm'
    })
    .component('sumWarningList', {
        template: require('./summary/sum_warning_list.html'),
        controller: sumWarningListCtrl,
        controllerAs: 'vm',
        bindings: {
            terms: '<'
        }
    })
    .component('warningProcess', {
        template: require('./warningProcess.html'),
        controller: warningProcessCtrl,
        controllerAs: 'vm',
        bindings: {
            terms: '<'
        }
    })
    .config(function ($stateProvider) {
        $stateProvider
            .state('currentWarningListBox', {
                url: '/currentWarningListBox/:terms',
                template: '<current-warning-list-box></current-warning-list-box>',
                title: '当日预警列表',
            })
            .state('warningCurIndex', {
                url: '/warningCurIndex/:terms',
                template: '<warning-cur-index></warning-cur-index>',
                title: '当日预警统计'
            })
            .state('historyWarningListBox', {
                url: '/historyWarningListBox/:terms',
                template: '<history-warning-list-box></history-warning-list-box>',
                title: '历史预警列表',
            })
            .state('warningHisIndex', {
                url: '/warningHisIndex/:terms',
                template: '<warning-his-index></warning-his-index>',
                title: '历史预警统计'
            })
            .state('warningDetail', {
                url: '/warningDetail?moduleType&id',
                template: '<warning-detail></warning-detail>',

                title: '预警详情'
            })
            .state('warningStatistics', {
                url: '/warningStatistics',
                template: '<warning-statistics current-work-area="vm.currentWorkArea" material-type="vm.materialType" module-type="vm.moduleType"></warning-statistics>',
                title: '预警统计'
            })
            .state('warningSumStatistics', {
                url: '/warningSumStatistics',
                template: '<warning-sum-statistics terms="vm.terms"></warning-sum-statistics>',
                title: '汇总预警统计'
            })
            .state('warningSumDetail', {
                url: '/warningSumDetail/:terms',
                template: '<warning-sum-detail></warning-sum-detail>',
                title: '汇总预警统计'
            })
            .state('warningProcess', {
                url: '/warningProcess?moduleType&id&engid',
                template: '<warning-process></warning-process>',
                title: '预警处理'
            })
    })
    .name;
