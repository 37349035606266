import {pileNo2Str} from '_services/transformers'

export default class LqTraService {

    constructor(http){
        this.http = http
    }

    getDefaultForm(terms){
        return this.http.get('/transport/defaultForm',terms).then(res=>res.data)
    }

    /**
     * 获取生产精度信息
     */
    getCurrentCollect(param){
        return this.http.get('/transport/summarizingInfo',param).then(res=>{
            let info = res.data
            if(info.transportCount  > 0){
                info.warningCarPercent =  info.upLimitRatio

            }else{
                info.warningCarPercent = 0
            }
            return info ;
        })
    }

    getCurrentDetail(param){
        return this.http.get('/transport/list',param).then(res=>{
            let  info = res.data ;
            if(info.length > 0){
                info.forEach(item=>{
                    if (item.beginSpreadingPileNo != null || item.endSpreadingPileNo != null){
                        item.beginSpreadingPileNo = pileNo2Str(item.beginSpreadingPileNo)
                        item.endSpreadingPileNo = pileNo2Str(item.endSpreadingPileNo)
                    }
                })
            }
            return info ;
        })
    }



    getHistoryDetail(param){
        return this.http.get('/transport/historyList',param).then(res=>{
            let info = res.data
            if(info.length > 0){
                info.forEach(item=>{
                    if(item.transportCount > 0){
                        item.warningCarPercent = item.upLimitRatio
                    }
                })
            }

            return info
        })
    }

    getTrack(id){
        return this.http.get('/transport/trackPlayback',{transportDataId:id}).then(res=>{
            return res.data
        })
    }
    getTrackV2(t){
        console.log(t)
        var param={
            workAreaId:t.workAreaId,
            tageCode:t.transporter.tageCode,
            startTime:t.endLoadingDate,
            endTime:t.beginSpreadingDate,
        }

        return this.http.get('/transport/trackPlaybackV2',param).then(res=>{
            return res.data
        })
    }
}

LqTraService.$inject = ['http']