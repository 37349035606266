import {addMinute, dateFormat} from "_services/transformers";

export default class SnRealTimeMainInfo {
    constructor(snRealTimeService, $scope, $state, $rootScope, $interval) {
        this.service = snRealTimeService

        this.scope = $scope

        this.state = $state

        this.mixplantList = []

        this._currentMixplant = null
        this._working = false

        this.mixplantIndex = 0

        this.isFirstRequest = true

        this.timeRange = -30

        this._runMixture = null

        this.terms = {}

        this.rootScope = $rootScope

        this.interval = $interval

        // 视频显示影藏
        this.videoSite1="0"
        this.videoSite2="0"

    }

    $onInit() {
        this.scope.$parent.vm.where = 'rel'
        this.startTimer()
    }

    $onChanges(changes) {
        let workArea = changes.currentWorkArea.currentValue
        if (workArea != null) {
            let section = workArea.section
            this.terms.engineerId = workArea.engineer.id
            this.terms.sectionId = section.id
            this.terms.workAreaId = workArea.id
            this.fetchMixplantList()
        } else {
            this.mixplantList = []
            this.currentMixplant = null
        }

        if(workArea != null){
            this.videoSite1=workArea.videoSite1
            this.videoSite2=workArea.videoSite2
        }
    }

    $onDestroy() {
        console.log('realTime   onDestry')
        this.interval.cancel(this.timer)
    }

    startTimer() {
        let that = this
        this.timer = this.interval(function () {
            that.fetchMixplantList()
        }, 30 * 1000)
    }

    get dischargeDate() {
        return this.rootScope.dischargeDate
    }


    set currentMixplant(m) {
        this._currentMixplant = m
        if (m != null) {
            this._working = m.working
            this.terms.mixplantId = m.mixplant.id
        }
        if (m != null && m.mixture != null) {
            this.runMixture = m.mixture
            this.strength =  m.strength
        } else {
            this.runMixture = null
        }
     }

    get currentMixplant() {
        return this._currentMixplant
    }
    set working(m) {
        this._working = m
    }

    get working() {
        return this._working
    }
    set runMixture(m) {
        this._runMixture = m
        if (m != null) {
            this.terms.mixtureId = m.id
        }
        return m
    }

    get runMixture() {
        return this._runMixture
    }

    fetchMixplantList() {
        let workArea = this.currentWorkArea;
        let now = new Date()
        let before = addMinute(now, this.timeRange)

        this.terms.startDateTime = dateFormat(before, 'yyyy-MM-dd hh:mm:00')
        this.terms.endDateTime = dateFormat(now, 'yyyy-MM-dd hh:mm:00')
        this.mixplantList = []
        this.service.fetchMixplantList(workArea.id, this.timeRange).then(resList => {
            this.mixplantList = resList
            if (Array.isArray(resList) && resList.length > 0) {
                if(this.isFirstRequest){
                    //第一次请求是 默认选择生产的拌和楼
                    this.mixplantIndex = 0
                    let showMixplant = resList[0];

                    // for (let i = 0; i < resList.length; i++) {
                    //     if (resList[i].mixture != null) {
                    //         showMixplant = resList[i]
                    //         this.mixplantIndex = i
                    //     }
                    // }
                    this.isFirstRequest = false
                    this.currentMixplant = showMixplant
                    this.working = this.currentMixplant.working
                }else{
                    //拌和楼数量变化了
                    if (this.mixplantIndex < 0 || this.mixplantIndex >= resList.length) {
                        this.mixplantIndex = 0
                    }
                    //保持监控同一个拌和楼
                    this.currentMixplant = resList[this.mixplantIndex]
                    this.working = this.currentMixplant.working
                }
            }
        })
    }


    /**
     * ←从右往左滑 下一个 拌和楼
     */
    nextMixplant() {
        // console.log('next mix')
        let size = this.mixplantList.length
        let next = ++this.mixplantIndex
        if (next >= size) {
            next = size - 1
            this.mixplantIndex = next
        }
        this.currentMixplant = this.mixplantList[next]
        this.working = this.currentMixplant.working
    }

    /**
     *  → 从左滑往右 前一个 拌和楼
     */
    preMixplant() {
        console.log('pre mix')
        let pre = --this.mixplantIndex
        if (pre < 0) {
            pre = 0
            this.mixplantIndex = pre
        }
        this.currentMixplant = this.mixplantList[pre]
        this.working = this.currentMixplant.working
    }

    /**
     * 跳转到视频监控
     */
     toSwCheckVideo() {
        this.state.go('swCheckVideoAfter')
    }

}

SnRealTimeMainInfo.$inject = ['snRealTimeService', '$scope','$state', "$rootScope", "$interval"]