import checkParamReady from '../warning_fn'

export default  class CurrentPanel {
    constructor($state,$stateParams,warningService){
        //this.level = $stateParams.level
        this.service = warningService;
        this.state = $state
        this.data = {}
    }

    toLevel(level,model){
        if(this.terms === null){
            return
        }
        this.terms.level = level
        if(this.terms.materialType == undefined){
            this.terms.materialType = this.materialType
        }
        if(this.terms.moduleType == undefined){
            this.terms.moduleType = this.moduleType
        }
        this.terms.model = model
        this.state.go('currentWarningListBox',{terms:JSON.stringify(this.terms)})
    }
    toWarningCurIndex(){
        if(this.terms === null){
            return
        }
        if(this.terms.materialType == undefined){
            this.terms.materialType = this.materialType
        }
        if(this.terms.moduleType == undefined){
            this.terms.moduleType = this.moduleType
        }
        console.log("tocurIndex terms:" +this.terms)
        this.state.go('warningCurIndex',{terms:JSON.stringify(this.terms)})
    }
    $onInit() {
        //this.warningStatistics();
        // console.log('组件初始化');
        // console.log('组件参数' + this.materialType + ',' + this.moduleType)
    }

    $doCheck(){
        if(this.terms == null){
            return
        }
        if(this.preWorkAreaId !== this.terms.workAreaId){
            this.warningStatistics()
        }else if(this.preDatePart !== this.terms.datePart){
            // console.log('mixture change:' + this.terms.mixtureId )
            this.warningStatistics()
        }
        this.preWorkAreaId = this.terms.workAreaId
        this.preDatePart = this.terms.datePart ;

    }

    get moduleTypeCN(){
        if(this.moduleType==='mixplant'){
            return "拌和"
        }
    }

    warningStatistics(){

        if(!checkParamReady.apply(this)){
            console.log('预警：参数不合法')
            return;
        }
        if(this.terms.datePart == null){
            return
        }
        if(this.terms.materialType == undefined){
            this.terms.materialType = this.materialType
        }
        if(this.terms.moduleType == undefined){
            this.terms.moduleType = this.moduleType
        }
        let terms = this.terms
        let param = {
            'engineerId':terms.engineerId,
            'sectionId':terms.sectionId,
            'workAreaId':terms.workAreaId,
            'datePart':terms.datePart,
            'materialType':terms.materialType,
            'moduleType':terms.moduleType
        }


        this.service.warningStatistics(param).then(data=>{
            this.data = data
        })
    }

}

CurrentPanel.$inject = ['$state','$stateParams','warningService']

